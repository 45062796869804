const ACTIVITY_LOG = {
  TYPES: {
    NOTE: 'note',
    WORKFLOW_EVENT: 'workflowEvent',
  },
};

Object.freeze(ACTIVITY_LOG);

export default ACTIVITY_LOG;
