const DOMAINS = {
  LOCALHOST: 'localhost',
  LOCALHOST_PROD: 'kubernetes.docker.internal',
  DEV1: 'dev1.app.gaviti.com',
  DEV2: 'dev2.app.gaviti.com',
  DEV3: 'dev3.app.gaviti.com',
  STAGING: 'stage.app.gaviti.com',
  US: 'us.app.gaviti.com',
  EU: 'app.gaviti.com',
};

Object.freeze(DOMAINS);

export default DOMAINS;
