export class Workflow {
  constructor(objInit) {
    this.id = objInit.id;
    this.deleted = objInit.deleted;
    this.createdAt = objInit.createdAt;
    this.template = objInit.template;
    this._id = objInit._id;

    Object.assign(this, objInit);
  }
}
