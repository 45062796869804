import { httpClient } from '@api';

export default {
  update: async (payload) => httpClient.post('tags/update', payload),
  delete: async (payload) => httpClient.post('tags/delete', payload),
  async list(payload) {
    try {
      const list = await httpClient.post('tags/list', payload);

      return list || [];
    } catch (err) {
      console.warn(err);
    }
  },
};
