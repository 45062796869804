export class Dispute {
  constructor({
    companyId = null,
    invoiceId = null,
    customerId = null,
    currency = null,
    disputeReason = null,
    amount = 0,
    id = null,
    createdAt = null,
    modifiedAt = null,
    action = null,
    localAmount = 0,
    daysInProgress = 0,
    attachments = [],
    customerName = null,
    lastNote = null,
    disputeNote = null,
  }) {
    this.companyId = companyId;
    this.invoiceId = invoiceId;
    this.customerId = customerId;
    this.currency = currency;
    this.disputeReason = disputeReason;
    this.amount = amount;
    this.id = id;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
    this.action = action;
    this.localAmount = localAmount;
    this.daysInProgress = daysInProgress;
    this.attachments = attachments;
    this.customerName = customerName;
    this.lastNote = lastNote;
    this.disputeNote = disputeNote;
  }
}
