import { isEqual, camelCase } from 'lodash-es';
import { vuexLocal } from '@plugins';
import Vuex from 'vuex';
import Vue from 'vue';
import { createStore } from 'vuex-extensions';

const isProduction = isEqual(import.meta.env.MODE, 'production');

const loadModules = () => {
  const context = import.meta.glob(['./modules/**/index.js'], {
    import: 'default',
    eager: true,
  });

  Object.keys(context).forEach((key) => {
    if (key.includes('example')) {
      delete context[key];
    }
  });

  const modules = Object.keys(context)
    .map((key) => ({
      key,
      name: camelCase(key.replace(/^\.\/modules\//, '').replace(/\/index\.js$/, '')),
    }))
    .filter((module) => !isEqual(module.name, 'example'))
    .reduce((ms, { key, name }) => {
      return {
        ...ms,
        [name]: context[key],
      };
    }, {});

  return { modules };
};

const { modules } = loadModules();

Vue.use(Vuex);

const store = createStore(Vuex.Store, {
  strict: !isProduction,
  modules,
  plugins: [vuexLocal],
});

export default store;
