/* eslint-disable */
import Vue from 'vue';

import { i18n } from '@plugins';
import { isNil } from 'lodash-es';

const notice = {};
let trackEvent = null;

/**
 * Create Notice plugin
 *
 * @param   {object}  Vue
 * @return  {object}
 */

notice.install = (Vue) => {
  const typeNotice = {
    iconPack: 'mdi',
    success: {
      theme: 'toasted-primary',
      className: 'v-alert v-sheet elevation-2 v-alert--dense toasted-alert success',
      position: 'bottom-right',
      duration: 5000,
    },
    error: {
      theme: 'toasted-primary',
      className: 'v-alert v-sheet elevation-2 v-alert--dense toasted-alert error',
      position: 'bottom-right',
      action: {
        text: i18n.t('menu.dismiss'),
        onClick: (e, toastObject) => {
          toastObject.goAway(0);

          if (!isNil(trackEvent)) {
            switch (trackEvent) {
              case 'unassignedUser':
                Vue.prototype.$VueAnalytics.track({
                  event_uuid: 'be468fc3-5ec5-4733-b366-e672333aee6b',
                  type: 'toast',
                  action: 'dismissed',
                  category: 'warning',
                  label: 'assign_default_account_owner',
                });

                break;

              default:
                break;
            }
          }
        },
      },
    },
  };

  Vue.prototype.$showToast = (type, text, event) => {
    if (!isNil(event)) {
      trackEvent = event;
    }

    if (typeNotice[type]) {
      return Vue.toasted.show(text, typeNotice[type]);
    }

    throw new Error(`Type "${type}" for notifications does not exist`);
  };
};

Vue.use(notice);

export default notice;
