const DEMO_DATA = {
  CREDIT_SAFE_REPORT: {
    creditScore: {
      currentCreditRating: {
        providerValue: {
          value: 89,
        },
        commonValue: 'A',
        creditLimit: {
          value: 89000,
          currency: 'USD',
        },
      },
    },
    paymentData: {
      dbt: {
        dbt: 13,
      },
    },
    negativeInformation: {
      legalFilingSummary: {
        taxLienFilings: 3,
        judgmentFilings: 4,
        sum: {
          value: 89000,
          currency: 'USD',
        },
      },
      possibleOfac: true,
    },
    additionalInformation: {
      inquiriesTrend: {
        inquiriesMonth_1: 10,
        inquiriesMonth_10: 30,
        inquiriesMonth_11: 20,
        inquiriesMonth_12: 50,
        inquiriesMonth_2: 40,
        inquiriesMonth_3: 60,
        inquiriesMonth_4: 80,
        inquiriesMonth_5: 70,
        inquiriesMonth_6: 40,
        inquiriesMonth_7: 50,
        inquiriesMonth_8: 10,
        inquiriesMonth_9: 40,
      },
      misc: {
        dbtTrend: -2,
      },
    },
    createdAt: Date.now(),
  },
};

Object.freeze(DEMO_DATA);

export default DEMO_DATA;
