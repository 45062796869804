import { httpClient } from '@api';

const companyId = httpClient.defaults.headers.common['GAV-company-id'];

export default {
  async find(params) {
    return httpClient.post('/dispute/list', {
      companyId,
      ...params,
    });
  },
  async findOne(disputeId) {
    return httpClient.post(`/dispute/find-one/${disputeId}`, {
      companyId,
    });
  },
  async update(dispute) {
    return httpClient.post('/dispute/update', {
      companyId,
      ...dispute,
    });
  },
  async generateDemo(params) {
    return httpClient.post('dispute/demo/generate', {
      companyId,
      ...params,
    });
  },
};
