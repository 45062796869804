import { filter, sortBy, isEqual } from 'lodash-es';

export default {
  FETCH_WORKFLOWS_LIST(state, data) {
    state.workflowsData = sortBy(data, 'name');
  },
  FETCH_ALLOWED_TAGS(state, data) {
    Object.keys(data).map((key) => {
      data[key] = data[key].filter((tag) => !isEqual(tag, 'INVOICE.DUEAMOUNT'));
    });

    state.allowedTags = data;
  },
  DELETE_WORKFLOWS(state, workflowIds) {
    const workflowIdsSet = new Set(workflowIds);
    state.workflowsData = filter(state.workflowsData, (workflow) => !workflowIdsSet.has(workflow.id));
  },
  COPY_WORKFLOW(state, data) {
    state.workflowsData.push(data);
    state.workflowsData = sortBy(state.workflowsData, 'name');
  },
  INSERT_WORKFLOWS(state, { newWorkflows }) {
    state.workflowsData.push(...newWorkflows);
    state.workflowsData = sortBy(state.workflowsData, 'name');
  },
  UPDATE_WORKFLOW(state, { workflowId, workflow: rawWorkflow }) {
    const workflow = state.workflowsData.find((workflow) => workflow.id === workflowId);

    Object.assign(workflow, rawWorkflow);
  },
  INSERT_WORKFLOW_RULE(state, { workflowId, workflowRule }) {
    const workflow = state.workflowsData.find((workflow) => workflow.id === workflowId);

    workflow.rules.push(workflowRule);
  },
  UPDATE_WORKFLOW_RULE(state, { workflowId, workflowRule: rawWorkflowRule }) {
    const { id } = rawWorkflowRule;
    const workflow = state.workflowsData.find((workflow) => workflow.id === workflowId);
    const workflowRule = workflow.rules.find((workflowRule) => workflowRule.id === id);

    Object.assign(workflowRule, {});
    Object.assign(workflowRule, rawWorkflowRule);
  },
  FETCH_TOTAL_LOCAL_BALANCE(state, payload) {
    state.totalLocalBalance = payload;
  },
  FETCH_INVOICE_COUNT_OUTSTANDING(state, payload) {
    state.invoicesCountOutstanding = payload;
  },
  SET_SELECTED: (state, payload) => {
    state.selected = payload;
  },
  SET_STATS: (state, payload) => {
    state.stats = payload;
  },
  SET_RULE_STATS: (state, payload) => {
    state.ruleStats = payload;
  },
};
