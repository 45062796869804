export const STATUSES = {
  NEW: 'new',
  READ: 'read',
};

const verifiedStatus = (status) => {
  if (![STATUSES.NEW, STATUSES.READ].includes(status)) {
    console.warn('Insight received with unsupported status');
  }

  return status;
};

export class Insight {
  constructor(objInit) {
    this.id = objInit.id;
    this.type = objInit.type;
    this.properties = objInit.properties;
    this.message = objInit.message;
    this.analysisStartDate = objInit.analysisStartDate;
    this.analysisEndDate = objInit.analysisEndDate;
    this.status = verifiedStatus(objInit.status);
    this.createdAt = objInit.createdAt;
    this.modifiedAt = objInit.modifiedAt;

    Object.assign(this, objInit);
  }
}
