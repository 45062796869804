export class CustomWidgetConfig {
  constructor(objInit) {
    this.id = objInit.id;
    this.title = objInit.title;
    this.description = objInit.description;
    this.dataSource = objInit.dataSource;
    this.customField = objInit.customField;
    this.filterField = objInit.filterField;
    this.type = objInit.type;
    this.excludeEmpty = objInit.excludeEmpty;
    this.sort = objInit.sort;
    this.calculation = objInit.calculation;
    this.isLocked = objInit.isLocked;
    this.isNew = false;
    this.search = objInit.search;
  }
}
