import { isEqual } from 'lodash-es';
import axios from 'axios';

import store from '@store';

const httpClient = axios.create({
  baseURL: `${import.meta.env.VITE_APP_NEW_API_GATEWAY_API_URL}v2`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const reqInterceptor = (req) => {
  const {
    data: { contentType = 'application/json' } = {
      contentType: 'application/json',
    },
  } = req;

  const token = localStorage.getItem('GAV_accessToken');

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  if (!isEqual(contentType, 'application/json')) {
    req.responseType = 'blob';

    if (isEqual(contentType, 'link-xlsx')) {
      req.responseType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    if (isEqual(contentType, 'csv')) {
      req.responseType = 'text';
    }
  }

  if (isEqual(req.data?.constructor?.name?.toLowerCase(), 'formdata')) {
    req.headers['Content-Type'] = 'multipart/form-data';
  }

  return req;
};

const resInterceptor = (res) => {
  const {
    data,
    data: { success, errorMsg, data: dataJson },
    headers: { 'content-type': contentType, 'content-disposition': filename },
  } = res;

  const fileName = filename?.split('filename=')?.[1];

  if (!contentType.startsWith('application/json')) {
    return {
      data,
      contentType,
      filename: fileName?.substring(1, (fileName?.length || 0) - 1),
    };
  }

  if (!success) {
    throw new Error(errorMsg);
  }

  return dataJson;
};

const resErrorInterceptor = (error) => {
  if (error?.response?.status === 401) {
    store.dispatch('auth/logout');
  }

  return Promise.reject(error);
};

httpClient.interceptors.request.use(reqInterceptor);
httpClient.interceptors.response.use(resInterceptor, resErrorInterceptor);

export default httpClient;
