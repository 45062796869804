import { i18n } from '@plugins';

export default {
  isLoading: ({ isLoading }) => isLoading,
  customers: ({ customers }) => customers,
  stats: ({ stats }) => stats,
  topCustomers: ({ topCustomers }) => topCustomers,
  overdueCustomers: ({ overdueCustomers }) => overdueCustomers,
  countOverdueCustomers: ({ countOverdueCustomers }) => countOverdueCustomers,
  percentageOverdueCustomers: ({ percentageOverdueCustomers }) => percentageOverdueCustomers,
  defaultHeaders: () => [
    'workflowEnabled',
    'stats.risk',
    'displayId',
    'displayName',
    'stats.totalLocalBalance',
    'stats.openCount',
    'contactName',
    'mlc.0',
    'phone',
    'workflow.name',
  ],
  selected: ({ selected }) => selected,
  widgets: (_state, _getters, _rootState, rootGetters) => {
    const { 'charts/creditLimitUsage': creditLimitUsage } = rootGetters;

    return {
      creditLimitUsage: {
        id: 'creditLimitUsage',
        name: i18n.t('dashboard.creditLimitUsage'),
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: i18n.t('dashboard.help.creditLimitUsage'),
        chart: creditLimitUsage,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: true,
      },
    };
  },
};
