export default {
  isChartLoading: false,
  invoicesChart: [],
  customersChart: [],
  bankChart: [],
  bankTransactionByWeekDaysChart: [],
  bankTransactionByMonthWeeksChart: [],
  agingBucketsChart: [],
  termsOfPaymentsChart: {},
  overdueInvoices: 0,
  outstandingInvoices: 0,
  dsoChart: [],
  paymentsForecastChart: {
    past: [],
    future: [],
    isClickable: false,
  },
  collectionRateCompareChart: {
    past: [],
    current: [],
  },
  paymentsForecastCompareChart: {
    past: [],
    future: [],
    type: undefined,
  },
  customersRiskChart: [],
  totalARCompareChart: {},
  overdueCustomersCompareChart: {},
  outstandingInvoicesCompareChart: {},
  agingBucketsCompareChart: {},
  upcomingOverdueAging: {},
  creditLimitUsage: {},
  paymentGatewayChart: [],
  paymentsHistoryChart: [],
  disputesChart: [],
  disputesHistoryChart: {},
  emailStatusMetricsChart: {},
};
