export class BankAccount {
  constructor(objInit) {
    this.id = objInit.id;
    this.bankName = objInit.bankName;
    this.accountNumber = objInit.accountNumber;
    this.balance = objInit.balance;
    this.adapterName = objInit.adapterName;
    this.source = objInit.source;
  }
}
