export default {
  SET_NOTIFICATION(state, payload) {
    state.notification = payload;
  },
  INCREASE_NOTIFICATINS_COUNTER(state, payload) {
    state.notificationsCount += payload;
  },
  DECREASE_NOTIFICATINS_COUNTER(state, payload) {
    state.notificationsCount -= payload;
  },
};
