import { get, keyBy, cloneDeep } from 'lodash-es';
import { i18n } from '@plugins';

const defaultLimit5MbInBytes = 5242880;

export default {
  companySettings: (state) => state.companySettings,
  companyCurrency: (state) => get(state, ['companySettings', 'currency'], null),
  companyDomain: (state) => get(state, ['companySettings', 'domain'], null),
  companyCountry: (state) => get(state, ['companySettings', 'country'], null),
  companyEmail: (state) => get(state, ['companySettings', 'email'], null),
  companyName: (state) => get(state, ['companySettings', 'name'], null),
  companyDescription: (state) => get(state, ['companySettings', 'description'], null),
  companyPhone: (state) => get(state, ['companySettings', 'phone'], null),
  companyUsers: (state) => keyBy(state.companyUsers, 'id'),
  companyWebsite: (state) => get(state, ['companySettings', 'website'], null),
  companyLogo: (state) => get(state, ['companySettings', 'logo'], null),
  companyAttachmentMaxFileSize: (state) =>
    get(state, ['companySettings', 'maxAttachmentFileSizeInBytes'], defaultLimit5MbInBytes),
  customFields: (state) => (field, lang, type) =>
    get(state, ['companySettings', 'customFields', type, field, 'translations', lang], field),
  customFieldsAll: (state) => (type) => get(state, ['companySettings', 'customFields', type], {}),
  defaultWorkflowId: (state) => get(state, ['companySettings', 'defaultWorkflowId'], null),
  loadingStatus: (state) => get(state, ['loadingStatus'], false),
  paymentInstructions: (state) => get(state, ['companySettings', 'paymentInstructions'], null),
  uiShowV2Invoices: (state) => get(state, ['companySettings', 'uiShowV2Invoices'], null),
  uiShowTestActivityLog: (state) => get(state, ['companySettings', 'uiShowTestActivityLog'], false),
  lastNoteDisplaySetting: (state) => get(state, ['companySettings', 'lastNoteDisplaySetting'], 'all'),
  paymentRestrictions: (state) => get(state, ['companySettings', 'paymentRestrictions'], {}),
  uiShowPaymentForecasting: (state) => get(state, ['companySettings', 'uiShowPaymentForecasting'], false),
  uiShowARTeam: (state) => get(state, ['companySettings', 'uiShowARTeam'], false),
  pauseWorkflowIfPendingReconciliation: (state) =>
    get(state, ['companySettings', 'pauseWorkflowIfPendingReconciliation'], false),
  allowPartialPayments: (state) => get(state, ['companySettings', 'allowPartialPayments'], false),
  useInvoiceExternalIdentifierBy: (state) => get(state, ['companySettings', 'useInvoiceExternalIdentifierBy'], null),
  notifyAboutPaymentTo: (state) => get(state, ['companySettings', 'notifyAboutPaymentTo'], []),
  notifyApaAboutMessageTo: (state) => get(state, ['companySettings', 'notifyApaAboutMessageTo'], []),
  userCustomerRelations: (state) => (id, lang) =>
    get(state, ['companySettings', 'userCustomerRelations', id, 'translations', lang], null),
  userCustomerRelationsAll: (state) => get(state, ['companySettings', 'userCustomerRelations'], {}),
  quickResponses: (state) => get(state, ['companySettings', 'quickResponses'], []),
  customerContactLevelAliases: (state) => get(state, ['companySettings', 'customerContactLevelAliases'], {}),
  applyCreditsOnDueDates: (state) => get(state, ['companySettings', 'applyCreditsOnDueDates'], false),
  clearPtpDateAutomatically: (state) => get(state, ['companySettings', 'clearPtpDateAutomatically'], false),
  clearClosedInvoicesActionsAutomatically: (state) =>
    get(state, ['companySettings', 'clearClosedInvoicesActionsAutomatically'], false),
  workflowCurrencyISO: (state) => get(state, ['companySettings', 'workflowCurrencyISO'], false),
  workflowPrintEnabled: (state) => get(state, ['companySettings', 'workflowPrintEnabled'], false),
  workflowsEnabled: (state) => get(state, ['companySettings', 'workflowsEnabled'], null),
  workflowSmsEnabled: (state) => get(state, ['companySettings', 'workflowSmsEnabled'], false),
  workflowCustomerMetricsCurrency: (state) => get(state, ['companySettings', 'workflowCustomerMetricsCurrency'], null),
  customerContactLevels: (state) => get(state, ['companySettings', 'customerContactLevels'], 1),
  weekStartsOn: (state) => get(state, ['companySettings', 'weekStartsOn'], null),
  dateFormat: (state) => {
    return get(state, ['companySettings', 'dateFormat'], {
      date: 'dd/MM/yyyy',
      time: 'hh:mm aa',
    });
  },
  isApaConnected: (state) => get(state, ['companySettings', 'isApaConnected'], true),
  isCreateMessageSent: (state) => get(state, ['companySettings', 'isCreateMessageSent'], false),
  customFieldsAsDueDate: (state) => get(state, ['companySettings', 'customFieldsAsDueDate'], false),
  businessModel: (state) => get(state, ['companySettings', 'businessModel'], null),
  agingBuckets: (state) => get(state, ['companySettings', 'agingBuckets'], []),
  erp: (state) => get(state, ['companySettings', 'erp'], null),
  settings: ({ settings }) => settings,
  usersList: ({ companyUsers }) => companyUsers,
  apaUsersList: ({ companyApaUsers }) => companyApaUsers,
  showLogs: (state) => state.showLogs,
  editableCustomerFields: (state) => get(state, ['companySettings', 'editableCustomerFields'], []),
  editableInvoiceFields: (state) => get(state, ['companySettings', 'editableInvoiceFields'], []),
  companyFlags: (state) => get(state, ['companySettings', 'flags'], {}),
  emailTracking: (state) => get(state, ['companySettings', 'emailTracking'], false),
  allWorkflowsRulesInactive: (state) => get(state, ['companySettings', 'allWorkflowsRulesInactive'], false),
  dialingCountryCode: (state) => get(state, ['companySettings', 'dialingCountryCode'], null),
  billingId: (state) => get(state, ['companySettings', 'billingId'], null),
  paymentSurcharge: (state) => get(state, ['companySettings', 'paymentSurcharge'], {}),
  paymentFiltering: (state) => get(state, ['companySettings', 'paymentFiltering'], []),
  toggleCreditManagement: (state) => get(state, ['companySettings', 'toggleCreditManagement'], false),
  toggleDisputeManagement: (state) => get(state, ['companySettings', 'toggleDisputeManagement'], false),
  toggleBankReconciliation: (state) => get(state, ['companySettings', 'toggleBankReconciliation'], false),
  toggleDisplayTransactionId: (state) => get(state, ['companySettings', 'toggleDisplayTransactionId'], false),
  toggleInsights: (state) => get(state, ['companySettings', 'toggleInsights'], false),
  toggleZapierIntegration: (state) => get(state, ['companySettings', 'toggleZapierIntegration'], false),
  toggleDocuments: (state) => get(state, ['companySettings', 'toggleDocuments'], false),
  toggleOpenAiRephrase: (state) => get(state, ['companySettings', 'toggleOpenAiRephrase'], false),
  toggleInvoicePreCollectionStatus: (state) =>
    get(state, ['companySettings', 'toggleInvoicePreCollectionStatus'], false),
  toggleCreditReport: (state) => get(state, ['companySettings', 'toggleCreditReport'], false),
  toggleCreditReportPDF: (state) => get(state, ['companySettings', 'toggleCreditReportPDF'], false),
  creditFormLimit: (state) => get(state, ['companySettings', 'creditFormLimit'], 0),
  creditReportLimit: (state) => get(state, ['companySettings', 'creditReportLimit'], 0),
  creditReportUsage: (state) => get(state, ['companySettings', 'creditReportUsage'], 0),
  creditFormUsage: (state) => get(state, ['companySettings', 'creditFormUsage'], 0),
  customFieldsLimit: (state) => get(state, ['companySettings', 'customFieldsLimit'], 3),
  userCustomerRelationsLimit: (state) => get(state, ['companySettings', 'userCustomerRelationsLimit'], 3),
  toggleCustomFields: (state) => get(state, ['companySettings', 'toggleCustomFields'], false),
  toggleWorkflowRuleLanguages: (state) => get(state, ['companySettings', 'toggleWorkflowRuleLanguages'], false),
  togglePhoneLink: (state) => get(state, ['companySettings', 'togglePhoneLink'], false),
  useSort: (state) => get(state, ['companySettings', 'useSort'], true),
  providerForms: (state) => get(state, ['companySettings', 'providerForms'], {}),
  openaiTokens: (state) => get(state, ['companySettings', 'openaiTokens'], {}),
  notifications: (state) => get(state, ['companySettings', 'notifications'], {}),
  creditSafeConnection: (state) => get(state, ['companySettings', 'creditSafeConnection'], {}),
  users: ({ companyUsers }) => {
    const users = cloneDeep(companyUsers);

    return users
      .filter(
        (user) =>
          user.accessControlRoleIds.length > 1 ||
          !user.accessControlRoleIds.includes('4976a37a-471d-4467-ae46-eec7f104cd59'),
      )
      .map((user) => {
        if (!user.activeInCompany) {
          user.name = `${user.name} (${i18n.t('workflow.itemInactive')})`;
        }

        return user;
      });
  },
  merchants: ({ merchants }) => merchants,
  workflowConfigurationsCheck: ({ workflowConfigurationsCheck }) => workflowConfigurationsCheck,
  sendApaOnBoardingEmail: (state) => get(state, ['companySettings', 'sendApaOnBoardingEmail'], false),
  apaOnBoardingEmailSettings: (state) => get(state, ['companySettings', 'apaOnBoardingEmailSettings'], {}),
  creditReportMonitoringSettings: (state) => get(state, ['companySettings', 'creditReportMonitoringSettings'], {}),
  allocationSuggestionEmailSettings: (state) =>
    get(state, ['companySettings', 'allocationSuggestionEmailSettings'], {}),
  modal: ({ modal }) => modal,
  companyAuditList: ({ companyAuditList }) => companyAuditList,
  companyAuditStats: ({ companyAuditStats }) => companyAuditStats,
  isAuditExportLoading: ({ isAuditExportLoading }) => isAuditExportLoading,
  cashApplicationInvoiceIdentifier: (state) =>
    get(state, ['companySettings', 'cashApplicationInvoiceIdentifier'], 'id'),
  autopayEnabled: (state) => get(state, ['companySettings', 'autopayEnabled'], false),
};
