/* eslint-disable */
import colors from 'vuetify/lib/util/colors';
import Vuetify from 'vuetify/lib/framework';
import Vue from 'vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        inverse: '#000000',
        primary: '#00C184',
        secondary: colors.grey.darken1,
        accent: colors.indigo.base,
        success: '#00C184',
        teal: '#0A034C',
        pruple: '#6C07D8',
        'light-pruple': '#EDE1FA',
        'risk-0': '#059964',
        'risk-1': '#6FC22D',
        'risk-2': '#FDCE29',
        'risk-3': '#FF7337',
        'risk-4': '#CA0202',
        'light-primary': '#6EEBAF',
        'blue-purple': '#4258C9',
        azure: '#0188D2',
        'light-azure': '#73B7EF',
        'light-teal': '#F5F5F8',
        orange: '#FF9800',
      },
      dark: {
        inverse: '#FFFFFF',
        primary: '#00C184',
        secondary: colors.grey.lighten1,
        accent: colors.indigo.base,
        success: '#00C184',
        teal: '#0A034C',
        pruple: '#6C07D8',
        'light-pruple': '#EDE1FA',
        'risk-0': '#059964',
        'risk-1': '#6FC22D',
        'risk-2': '#FDCE29',
        'risk-3': '#FF7337',
        'risk-4': '#CA0202',
        'light-primary': '#6EEBAF',
        'blue-purple': '#4258C9',
        azure: '#0188D2',
        'light-azure': '#73B7EF',
        'light-teal': '#121212',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
