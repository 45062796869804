import { isEqual } from 'lodash-es';

export default {
  SET_LAST_DATA_UPDATE: (state, payload) => {
    state.lastDataUpdate = payload;
  },
  SET_DEVICE_ORIENTATION: (state, payload) => {
    state.deviceOrientation = payload;
  },
  SET_IS_DEVICE_MOBILE: (state, payload) => {
    state.isDeviceMobile = payload;
  },
  SET_SIDEBAR: (state, payload) => {
    state.sidebar = payload;
  },
  SET_MENU_BUTTON: (state, payload) => {
    state.menuButton = payload;
  },
  SET_APP_NOTIFICATIONS: (state, payload) => {
    state.appNotifications = payload;
  },
  SET_APP_DEMO_NOTIFICATIONS: (state, payload) => {
    state.demoAppNotifications = payload;
  },
  SET_APP_NOTIFICATIONS_COUNT: (state, payload) => {
    state.appNotificationsCount = payload;
  },
  SET_UPDATE_APP_NOTIFICATION_STATUS: (state, payload) => {
    const notification = state.appNotifications.find(({ id }) => isEqual(id, payload.id));

    notification.status = 'read';
    state.appNotificationsCount -= 1;
  },
  SET_UPDATE_DEMO_APP_NOTIFICATION_STATUS: (state, payload) => {
    const notification = state.demoAppNotifications.find(({ id }) => isEqual(id, payload.id));

    notification.status = 'read';
    state.appNotificationsCount -= 1;
  },
  SET_CURRENT_PAGE: (state, payload) => {
    state.currentPage = payload;
  },
  SET_PAGES_COUNT: (state, payload) => {
    state.pagesCount = payload;
  },
  SET_RESET_APP_NOTIFICATIONS: (state) => {
    state.appNotifications = [];
    state.currentPage = 1;
    state.pagesCount = 0;
  },
  SET_SHOW_MODAL: (state, payload) => {
    state.showModal = payload;
  },
  SET_SHOW_APP_DRAWER: (state, payload) => {
    state.showAppDrawer = payload;
  },
  SET_APP_DRAWER_MODE: (state, payload) => {
    state.appDrawerMode = payload;
  },
  SET_APP_DRAWER_TITLE: (state, payload) => {
    state.appDrawerTitle = payload;
  },
  SET_APP_NOTIFICATIONS_CUSTOMERS: (state, payload) => {
    state.customers = payload;
  },
  SET_APP_NOTIFICATIONS_INVOICES: (state, payload) => {
    state.invoices = payload;
  },
  SET_IS_APP_NOTIFICATIONS_LOADING: (state, payload) => {
    state.isAppNotificationsLoading = payload;
  },
  SET_ACCESS_CONTROL: (state, payload) => {
    state.accessControl = payload;
  },
  SET_DARK_MODE: (state) => {
    state.darkMode = !state.darkMode;
  },
  TOGGLE_CONFETTI: (state, payload) => {
    state.showConfetti = payload;
  },
  SET_APP_LOADER: (state, payload) => {
    state.isAppLoading = payload;
  },
};
