import { isEqual } from 'lodash-es';

export default {
  SET_BANK_TRANSACTIONS: (state, list) => {
    state.list = list;
  },
  SET_TOTAL_BANK_TRANSACTIONS: (state, total) => {
    state.total = total;
  },
  SET_TRANSACTIONS_CUSTOMERS: (state, customers) => {
    state.customers = customers;
  },
  SET_TRANSACTIONS_INVOICES: (state, invoices) => {
    state.invoices = invoices;
  },
  SET_SELECTED: (state, payload) => {
    state.selected = payload;
  },
  SET_BANK_ACCOUNTS: (state, list) => {
    state.accountList = list;
  },
  SET_IS_BANK_RECONNECT_NEEDED: (state, res) => {
    state.isBankReconnectNeeded = res;
  },
  SET_TABLE_OPTIONS: (state, payload) => {
    state.tableOptions = payload;
  },
  SET_ACCOUNT_IDS: (state, payload) => {
    state.accountIds = payload;
  },
  UPDATE_ALLOCATION_SUGGESTION_STATE: (state, { id, accountId }) => {
    const transaction = state.list.find(
      ({ id: transactionId, accountId: transactionAccountId }) =>
        isEqual(id, transactionId) && isEqual(accountId, transactionAccountId),
    );

    transaction.isSuggestionsDismissed = true;
  },
  RESET_TABLE_OPTIONS: (state) => {
    state.tableOptions.page = 1;
  },
};
