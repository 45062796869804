import { isString, isFinite } from 'lodash-es';
import Dinero from 'dinero.js';

const THOUSAND = 1000;
const MILLION = 1000000;

export function formatCurrency(rawAmount, currency, showMinor = true) {
  let amount = parseFloat(rawAmount);

  if (!isFinite(amount) || !isString(currency)) {
    return '';
  }

  amount = Math.round(amount * 100);

  const format = showMinor ? undefined : '$0,0';

  return Dinero({ amount, currency }).toFormat(format);
}

export function formatCurrencyBigNumbers(rawAmount, currency) {
  let marker = '';
  let amount = Math.abs(rawAmount);

  if (amount >= THOUSAND && amount < MILLION) {
    marker = 'K';
    amount /= THOUSAND;
  } else if (amount >= MILLION) {
    marker = 'M';
    amount /= MILLION;
  }

  amount = rawAmount < 0 ? amount * -1 : amount;

  return `${formatCurrency(amount, currency, false)}${marker}`;
}
