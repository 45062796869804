import { isNil, debounce } from 'lodash-es';
import textFit from 'textfit';

const onResize = (el) => {
  const context = el.__ctx;
  const parentElement = el.parentElement;
  const parentStyle = window.getComputedStyle(parentElement);
  const parentPaddingLeft = parseInt(parentStyle.paddingLeft, 10);
  const parentPaddingRight = parseInt(parentStyle.paddingRight, 10);

  el.style.width = `${parentElement.offsetWidth - parentPaddingLeft - parentPaddingRight}px`;

  if (document.body.contains(el)) {
    textFit(el, {
      minFontSize: context.minFontSize,
      maxFontSize: context.maxFontSize,
      widthOnly: context.widthOnly,
    });
  }
};

const defaultOptions = {
  minFontSize: 10,
  maxFontSize: 40,
  widthOnly: true,
};

const ResizeText = {
  inserted: (el, binding) => {
    if (binding.modifiers['no-fit']) return;

    const context = {
      ...defaultOptions,
      ...binding.value,
    };

    el.__ctx = context;

    const resizeHandler = debounce(() => {
      onResize(el);
    }, 200);

    el.__resizeHandler = resizeHandler;

    resizeHandler();

    if (!isNil(window)) {
      window.addEventListener('resize', resizeHandler, { passive: true });
    }

    const observer = new MutationObserver(() => {
      onResize(el);
    });

    observer.observe(el, { attributes: true });
    el.__observer = observer;
  },
  update: (el) => {
    el.__value = el.getAttribute('data-value');
    el.textContent = el.__value;
  },
  unbind: (el) => {
    if (!isNil(window)) {
      window.removeEventListener('resize', el.__resizeHandler, { passive: true });
    }

    if (el.__observer) {
      el.__observer.disconnect();
    }

    if (el.__animationFrameId) {
      cancelAnimationFrame(el.__animationFrameId);
    }
  },
};

export default ResizeText;
