export class BankReconciliation {
  constructor(objInit) {
    this.accountId = objInit.accountId;
    this.id = objInit.id;
    this.amount = objInit.amount;
    this.date = objInit.date;
    this.localAmount = objInit.localAmount;
    this.description = objInit.description;
    this.balance = objInit.balance;
    this.status = objInit.status;
    this.allocations = objInit.allocations;
    this.comment = objInit.comment;
    this.currency = objInit.currency;
    this.suggestions = objInit.suggestions;
    this.isSuggestionsDismissed = objInit.isSuggestionsDismissed;
    this.reconciledAt = objInit.reconciledAt;
  }
}
