import { bankReconciliationService, customerService, invoiceService } from '@services';
import { isEmpty, uniq, isNil, keyBy } from 'lodash-es';

export default {
  fetchTransactions: async ({ commit, dispatch }, params = {}) => {
    try {
      const { data, total, accountIds } = await bankReconciliationService.list(params);

      await Promise.all([
        dispatch('fetchTransactionCustomers', { data }),
        dispatch('fetchTransactionInvoices', { data }),
      ]);

      commit('SET_BANK_TRANSACTIONS', data);
      commit('SET_TOTAL_BANK_TRANSACTIONS', total);
      commit('SET_ACCOUNT_IDS', accountIds);
    } catch (error) {
      console.warn(error);
    }
  },
  async fetchTransactionCustomers({ commit, state }, { data }) {
    try {
      if (Array.isArray(data) && data.length > 0) {
        const customerIds = uniq(
          [
            ...data
              .filter(({ allocations }) => !isNil(allocations))
              .map(({ allocations }) => allocations.map(({ customerId }) => customerId))
              .flat(),
          ].filter(Boolean),
        );

        if (!isEmpty(customerIds)) {
          await customerService
            .list({
              companyId: this._vm.$auth.user()?.selectedCompany?.id,
              search: { ids: customerIds },
            })
            .then(({ list: customers }) => commit('SET_TRANSACTIONS_CUSTOMERS', keyBy(customers, 'id')));
        }
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  async fetchTransactionInvoices({ commit, state }, { data }) {
    try {
      if (Array.isArray(data) && data.length > 0) {
        const invoiceIds = uniq(
          [
            ...data
              .filter(({ allocations }) => !isNil(allocations))
              .map(({ allocations }) => allocations.map(({ invoiceIds }) => invoiceIds).flat())
              .flat(),
            ...data
              .filter(({ suggestions, isSuggestionsDismissed }) => !isNil(suggestions) && !isSuggestionsDismissed)
              .map(({ suggestions }) => suggestions.map(({ invoiceIds }) => invoiceIds).flat())
              .flat(),
          ].filter(Boolean),
        );

        if (!isEmpty(invoiceIds)) {
          await invoiceService
            .list({
              companyId: this._vm.$auth.user()?.selectedCompany?.id,
              search: { ids: invoiceIds },
            })
            .then(({ list: invoices }) => commit('SET_TRANSACTIONS_INVOICES', keyBy(invoices, 'id')));
        }
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  exportBankTransactions: async ({}, params = {}) => {
    try {
      return await bankReconciliationService.list(params);
    } catch (error) {
      console.warn(error);
    }
  },
  fetchBankTransaction: async ({}, params = {}) => {
    try {
      return await bankReconciliationService.findOne(params);
    } catch (error) {
      console.warn(error);
    }
  },
  updateTransaction: async ({}, params = {}) => {
    try {
      await bankReconciliationService.update(params);
    } catch (error) {
      console.warn(error);
    }
  },
  setSelected: ({ commit }, payload) => {
    commit('SET_SELECTED', payload);
  },
  fetchAccounts: async ({ commit }, params = {}) => {
    try {
      const { data, isReconnectNeeded } = await bankReconciliationService.accountList(params);

      commit('SET_BANK_ACCOUNTS', data);
      commit('SET_IS_BANK_RECONNECT_NEEDED', isReconnectNeeded);
    } catch (error) {
      console.warn(error);
    }
  },
  updateTableOptions: ({ commit }, payload) => {
    commit('SET_TABLE_OPTIONS', payload);
  },
  updateAllocationSuggestionState: ({ commit }, params = {}) => {
    try {
      commit('UPDATE_ALLOCATION_SUGGESTION_STATE', params);
    } catch (error) {
      console.warn(error);
    }
  },
  resetTableOptions: ({ commit }) => {
    commit('RESET_TABLE_OPTIONS');
  },
};
