export default {
  items({ items }) {
    return items;
  },
  serverItemsLength({ serverItemsLength }) {
    return serverItemsLength;
  },
  selected({ selected }) {
    return selected;
  },
  dispute({ dispute }) {
    return dispute;
  },
};
