import Vue from 'vue';

const plaid = {
  install(Vue) {
    Vue.prototype.$plaid = {
      init() {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

        const firstScript = document.getElementsByTagName('script')[0];

        firstScript.parentNode.insertBefore(script, firstScript);
      },
    };
  },
};

Vue.use(plaid);

export default plaid;
