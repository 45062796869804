/* eslint-disable */
import { isFunction, isString } from 'lodash-es';
import Vue from 'vue';

const clarity = {
  install(Vue) {
    Vue.prototype.$clarity = {
      setup() {
        (function (c, l, a, r, i, t, y) {
          c[a] =
            c[a] ||
            function () {
              (c[a].q = c[a].q || []).push(arguments);
            };

          t = l.createElement(r);
          t.async = 1;
          t.src = `https://www.clarity.ms/tag/${i}`;
          y = l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t, y);
        })(window, document, 'clarity', 'script', import.meta.env.VITE_APP_CLARITY_ID);
      },
      set(key, value) {
        if (isFunction(window.clarity)) {
          window.clarity('set', key, value);
        }
      },
      async identify(email) {
        if (isFunction(window.clarity) && isString(email)) {
          // LINK: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
          const uint8Email = new TextEncoder().encode(email.toLowerCase());
          const hashBuffer = await crypto.subtle.digest('SHA-256', uint8Email);
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hashHexEmail = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

          window.clarity('identify', hashHexEmail);
          window.clarity('set', 'Custom User ID', hashHexEmail);
        } else {
          console.error('Clarity not ready or email not provided');
        }
      },
    };
  },
};

Vue.use(clarity);

export default clarity;
