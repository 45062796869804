/* eslint-disable */
import store from '@store';
import axios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.common.js';
import router from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.common.js';
import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import AccessControl from 'accesscontrol';
import IdleJs from 'idle-js';
import { isEmpty, isNil } from 'lodash-es';
import Vue from 'vue';

const { VITE_APP_NEW_API_GATEWAY_API_URL, VITE_APP_GA_ID } = import.meta.env;

const setUserDataForGA = (user) => {
  if (window.gtag && user.email) {
    window.gtag('config', VITE_APP_GA_ID, {
      user_id: user.id,
    });

    window.gtag('set', 'user_properties', {
      userId: user.id,
      companyId: user?.selectedCompany?.id,
    });
  }
};

Vue.use(VueAuth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
  drivers: {
    http: axios,
    auth: {
      request(req) {
        const headers = {};
        const companyId = localStorage.getItem('GAV-company-id');

        if (companyId) {
          headers['GAV-company-id'] = companyId;
        }

        this.drivers.http.setHeaders.call(this, req, headers);
      },
      response({ request: { responseURL }, data: dataResponse = {} }) {
        if ([this.options.loginData.url, this.options.refreshData.url].includes(responseURL)) {
          const { success, data: { accessTokenExpiresAt, refreshTokenExpiresAt, requireOTP, otpAuth } = {} } =
            dataResponse;

          if (success) {
            const accessToken = 'dummy access token';

            this.token(this.options.tokenDefaultName, accessToken);
            this.token(this.options.accessTokenExpiresAtName, accessTokenExpiresAt);

            if (requireOTP && this.requireOtp) {
              this.requireOtp(otpAuth);
            }

            if (refreshTokenExpiresAt) {
              this.token(this.options.refreshTokenName, 'dummy refresh token');
              this.token(this.options.refreshTokenExpiresAtName, refreshTokenExpiresAt);
            }

            return accessToken;
          } else {
            throw new Error();
          }
        }
      },
    },
    router,
  },
  options: {
    stores: ['storage'],
    tokenDefaultName: 'GAV_accessToken',
    refreshTokenName: 'GAV_refreshToken',
    accessTokenExpiresAtName: 'GAV_accessTokenExpiresAt',
    refreshTokenExpiresAtName: 'GAV_refreshTokenExpiresAt',
    loginData: {
      url: `${VITE_APP_NEW_API_GATEWAY_API_URL}v2/auth/login`,
      method: 'POST',
      redirect: false,
    },
    logoutData: {
      url: `${VITE_APP_NEW_API_GATEWAY_API_URL}v2/auth/logout`,
      method: 'POST',
      redirect: false,
      makeRequest: false,
    },
    registerData: {
      url: `${VITE_APP_NEW_API_GATEWAY_API_URL}v2/auth/register`,
      method: 'POST',
      redirect: false,
    },
    fetchData: {
      url: `${VITE_APP_NEW_API_GATEWAY_API_URL}v2/user/me`,
      method: 'POST',
    },
    refreshData: {
      url: `${VITE_APP_NEW_API_GATEWAY_API_URL}v2/auth/refresh`,
      method: 'POST',
      enabled: true,
      interval: 15,
    },
    parseUserData(user) {
      if (user) {
        store.dispatch('auth/setUser', user);

        if (user?.idleTimeDisconnect) {
          const idle = new IdleJs({
            idle: user.idleTimeDisconnect,
            onIdle() {
              if (Vue.auth.check()) {
                store.dispatch('auth/logout');
              }
            },
          });

          idle.start();
        }

        if (user?.id) {
          setUserDataForGA(user);

          setInterval(() => setUserDataForGA(user), 15 * 60 * 1000);

          if (user?.ssoEnabled) {
            store.dispatch('auth/setSecurityPolicy', { [user.id]: user.securityPolicy });
          }
        }

        if (user?.selectedCompany) {
          if (user?.selectedCompany?.id) {
            Vue.axios.defaults.headers.common['GAV-company-id'] = user.selectedCompany.id;

            localStorage.setItem('GAV-company-id', user.selectedCompany.id);
          }

          if (user?.defaultDashboardId) {
            store.dispatch('dashboard/setDefaultDashboardId', { defaultDashboardId: user.defaultDashboardId });
          } else {
            store.dispatch('dashboard/setDefaultDashboardId', {
              defaultDashboardId: '8a13f76e-66af-416a-b4a1-a7ea57d189c1',
            });
          }

          if (!isNil(user.permissions) && !isEmpty(user.permissions)) {
            const accessControl = new AccessControl(user.permissions);

            store.dispatch('app/setAccessControl', accessControl);
          }
        }
      }

      return user;
    },
  },
});
