export default {
  appInsights: [],
  appInsightsCount: 0,
  currentPage: 1,
  pagesCount: 0,
  showModal: {},
  customers: [],
  invoices: [],
  isAppInsightsLoading: false,
  demoAppInsights: [],
  demoAppInsightsCount: 0,
};
