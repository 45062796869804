export default {
  dashboards: [],
  selectedDashboardId: undefined,
  selectedDashboard: [],
  selectedLayout: [],
  selectedWidgets: [],
  gavitiDashboardId: '8a13f76e-66af-416a-b4a1-a7ea57d189c1',
  defaultDashboardId: undefined,
  dashboardEditMode: false,
  selectedDashboardName: '',
  defaultDashboardLayout: [
    {
      w: 2,
      h: 4,
      i: 'totalAR',
    },
    {
      w: 2,
      h: 4,
      i: 'dso',
    },
    {
      w: 2,
      h: 4,
      i: 'openInvoices',
    },
    {
      w: 4,
      h: 8,
      i: 'agingBuckets',
    },
    {
      w: 4,
      h: 8,
      i: 'openInvoicesAmountChart',
    },
    {
      w: 3,
      h: 4,
      i: 'closedInvoices',
    },
    {
      w: 3,
      h: 4,
      i: 'workflowsActivities',
    },
    {
      w: 3,
      h: 4,
      i: 'notesActivities',
    },
    {
      w: 4,
      h: 8,
      i: 'topDebtors',
    },
    {
      w: 4,
      h: 8,
      i: 'pastDueCustomers',
    },
    {
      w: 4,
      h: 8,
      i: 'payments',
    },
    {
      w: 4,
      h: 8,
      i: 'openInvoicesChart',
    },
    {
      w: 4,
      h: 8,
      i: 'customersChart',
    },
    {
      w: 4,
      h: 8,
      i: 'invoicesChart',
    },
    {
      w: 2,
      h: 4,
      i: 'cei',
    },
    {
      w: 4,
      h: 4,
      i: 'manualNotesActivities',
    },
    {
      w: 4,
      h: 4,
      i: 'inflation',
    },
    {
      w: 4,
      h: 4,
      i: 'interest',
    },
    {
      w: 2,
      h: 4,
      i: 'dsoStandard',
    },
    {
      w: 2,
      h: 4,
      i: 'add',
    },
    {
      w: 2,
      h: 4,
      i: 'mdd',
    },
    {
      w: 2,
      h: 4,
      i: 'collectionRate',
    },
    {
      w: 4,
      h: 8,
      i: 'customersRiskChart',
    },
    {
      w: 4,
      h: 8,
      i: 'termsOfPayments',
    },
    {
      w: 3,
      h: 4,
      i: 'manualTimeSaved',
    },
    {
      w: 4,
      h: 8,
      i: 'paymentGatewayChart',
    },
    {
      w: 4,
      h: 8,
      i: 'paymentsHistoryChart',
    },
    {
      w: 4,
      h: 8,
      i: 'disputesChart',
    },
    {
      w: 2,
      h: 4,
      i: 'customersCreditLimit',
    },
    {
      w: 4,
      h: 8,
      i: 'emailStatusMetricsChart',
    },
  ],
  widgets: [],
  trends: {
    totalAr: undefined,
    countOutstandingInvoices: undefined,
  },
  selectedDashboardHistory: [],
};
