import colors from 'vuetify/lib/util/colors';

import { vuetify } from '@plugins';

const primary = vuetify.framework.theme.themes.light.primary;

const AGING_BUCKETS = {
  UPCOMING: 'upcoming',
  OVERDUE: 'overdue',
  DAY_FROM: 'dayFrom',
  CLOSED: 'closed',
  OVERDUE_BUCKET: 'overdueBucket',
  CLOSED_OVERDUE_BUCKET: 'closedOverdueBucket',
  LOCAL_BALANCE: 'localBalance',
  COUNT: 'count',
  UPCOMING_COLOR: primary,
  OVERDUE_COLORS: [
    colors.red.lighten4,
    colors.red.lighten3,
    colors.red.lighten2,
    colors.red.lighten1,
    colors.red.darken1,
    colors.red.darken2,
    colors.red.darken3,
    colors.red.darken4,
  ],
};

Object.freeze(AGING_BUCKETS);

export default AGING_BUCKETS;
