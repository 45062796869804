import { httpClient } from '@api';

import { Workflow } from './workflow.dto';

export default {
  listTags: async (payload) => httpClient.post('workflows/tags', payload),
  create: async (payload) => httpClient.post('workflows/insert', payload),
  update: async (payload) => httpClient.post('workflows/update', payload),
  duplicate: async (payload) => httpClient.post('workflows/copy', payload),
  run: async (payload) => httpClient.post('workflows/run', payload),
  export: async (payload) => httpClient.post('workflows/export', payload),
  stats: async (payload) => httpClient.post('workflows/stats', payload),
  createRule: async (payload) => httpClient.post('workflows/insertRule', payload),
  updateRule: async (payload) => httpClient.post('workflows/updateRule', payload),
  rephraseTemplate: async (payload) => httpClient.post('workflow/rephrase', payload),
  ruleStats: async (payload) => httpClient.post('workflow/rule/stats', payload),
  getTemplates: async (payload) => httpClient.post('workflow/templates/list', payload),
  insertTemplate: async (payload) => httpClient.post('workflow/templates/insert', payload),
  updateTemplate: async (payload) => httpClient.post('workflow/templates/update', payload),
  deleteTemplate: async (payload) => httpClient.post('workflow/templates/delete', payload),
  workflowTemplateDefaults: async (payload) => httpClient.post('workflow/templates/defaults', payload),
  defaultWorkflowRuleFields: async (payload) => httpClient.post('workflow/templates/defaults/rule', payload),
  upload: async (payload) => httpClient.post('workflow/template/upload', payload),
  async list(payload) {
    try {
      const list = await httpClient.post('workflows/find', payload);
      const normalizedList = list.map((item) => new Workflow(item));
      return normalizedList;
    } catch (err) {
      console.warn(err);
    }
  },
};
