export class Record {
  constructor(objInit) {
    this._id = objInit._id;
    this.createdAt = objInit.createdAt;
    this.status = objInit.status;
    this.type = objInit.type;
    this.folderName = objInit.folderName;
    this.meta = objInit.meta;
    this.errors = objInit.errors;
    this.startedAt = objInit.startedAt;
    this.finishedAt = objInit.finishedAt;
    this.duration = objInit.duration;
    this.fatalError = objInit.fatalError;
    this.adapterCode = objInit.adapterCode;
    this.messagePrefix = objInit.messagePrefix;
    this.dataTimestamp = objInit.dataTimestamp;

    Object.assign(this, objInit);
  }
}
