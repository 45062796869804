import { isEqual, isNil, last } from 'lodash-es';

import { dashboardService } from '@services';
import { i18n } from '@plugins';

export default {
  async fetchDashboards({ commit, dispatch }) {
    try {
      const dashboards = await dashboardService.list({
        params: {
          companyId: this._vm.$auth.user().selectedCompany.id,
        },
      });

      dashboards.unshift({
        name: 'Dashboard',
        id: '8a13f76e-66af-416a-b4a1-a7ea57d189c1',
        layout: [],
        deleted: false,
        locked: true,
      });

      commit('SET_DASHBOARDS', dashboards);
    } catch ({ message }) {
      console.error(message);
    } finally {
      await dispatch('customWidgets/fetchCustomWidgets', null, { root: true });
    }
  },
  setDefaultDashboardId: async ({ commit, dispatch }, { companyId, defaultDashboardId }) => {
    commit('SET_DEFAULT_DASHBOARD_ID', defaultDashboardId);

    if (companyId) {
      await dispatch(
        'settings/updateCompanyUserSettings',
        {
          companyId,
          settings: {
            defaultDashboardId,
          },
        },
        { root: true },
      );
    }
  },
  setSelectedDashboard: ({ commit }, selectedDashboardId) => {
    commit('SET_SELECTED_DASHBOARD', { selectedDashboardId });
    commit('ADD_SELECTED_DASHBOARD_TO_HISTORY', selectedDashboardId);
  },
  setChartCentralDashboard: ({ commit }) => {
    commit('SET_CHART_CENTRAL_DASHBOARD');
  },
  updateSelectedLayout: ({ commit }, payload) => {
    commit('UPDATE_SELECTED_LAYOUT', payload);
  },
  setDashboardEditMode: ({ commit }, dashboardEditMode) => {
    commit('SET_DASHBOARD_EDIT_MODE', dashboardEditMode);
  },
  setSelectedWidgets: ({ commit }, widgets) => {
    commit('SET_SELECTED_WIDGETS', widgets);
  },
  setSelectedDashboardName: ({ commit }, selectedDashboardName) => {
    commit('SET_SELECTED_DASHBOARD_NAME', selectedDashboardName);
  },
  updateWidget: ({ commit }, payload) => {
    commit('UPDATE_WIDGET', payload);
  },
  createDashboard: ({ commit, state, dispatch }) => {
    const newDashboard = {
      id: 'new',
      name: `${i18n.t('nav.newDashboard')} ${state.dashboards.length + 1}`,
      layout: [],
    };

    commit('CREATE_DASHBOARD', newDashboard);
    commit('SET_SELECTED_DASHBOARD', { selectedDashboardId: newDashboard.id });

    dispatch('setDashboardEditMode', true);
  },
  deleteDashboard: async ({ commit, state, dispatch }, { companyId, dashboardId }) => {
    try {
      commit('DELETE_DASHBOARD', dashboardId);

      await dashboardService.delete({ dashboardId, companyId });

      commit('SET_SELECTED_DASHBOARD', { selectedDashboardId: state.defaultDashboardId });
    } catch ({ message }) {
      console.error(message);
    } finally {
      await dispatch('fetchDashboards');
    }
  },
  discardDashboard: ({ commit }) => {
    commit('DISCARD_NEW_DASHBOARD');
    commit('customWidgets/RESET_IS_NEW', null, { root: true });
  },
  saveDashboard: async ({ commit, dispatch, state }, { id, companyId, name, layout }) => {
    try {
      const cleanLayout = layout.map((item) => {
        if (!isNil(item.isDraggable)) delete item.isDraggable;
        if (!isNil(item.moved)) delete item.moved;

        return item;
      });

      if (isEqual(id, 'new')) {
        await dashboardService.create({
          companyId,
          name,
          layout: cleanLayout,
        });
      } else {
        await dashboardService.update({
          dashboardId: id,
          payload: {
            companyId,
            name,
            layout: cleanLayout,
          },
        });
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      dispatch('setDashboardEditMode', false);

      await dispatch('fetchDashboards');

      if (isEqual(id, 'new')) {
        const lastDashboard = last(state.dashboards);

        dispatch('setSelectedDashboard', lastDashboard.id);
      }

      commit('customWidgets/RESET_IS_NEW', null, { root: true });
    }
  },
  addWidgetToSelectedWidgets: ({ commit, state }, id) => {
    const widgets = [...state.selectedWidgets, id];

    commit('SET_SELECTED_WIDGETS', widgets);
  },
  removeWidgetToSelectedWidgets: ({ commit, state }, id) => {
    const widgets = state.selectedWidgets.filter((widgetId) => !isEqual(widgetId, id));

    commit('SET_SELECTED_WIDGETS', widgets);
  },
};
