import { httpClient } from '@api';

export default {
  fetchSettings: async (payload) => httpClient.post('bo/settings/find', payload),
  updateSettings: async (payload) => httpClient.post('bo/settings/update', payload),
  fetchAdapters: async (payload) => httpClient.post('bo/adapters/list', payload),
  updateAdapters: async (payload) => httpClient.post('bo/adapters/update', payload),
  uploadAdapters: async (payload) => httpClient.post('bo/adapters/upload', payload),
  deleteAdapter: async (payload) => httpClient.post('bo/adapters/delete', payload),
  fetchAdaptersByCompanyId: async ({ companyId }) => httpClient.get(`bo/adapters/list/${companyId}`),
  fetchRenewalsReport: async (payload) => httpClient.post('bo/reports/renewals', payload),
  clearCompany: async (payload) => httpClient.post('bo/company/clear', payload),
  deleteCompany: async (payload) => httpClient.post('bo/company/delete', payload),
  createAdapterEmail: async (payload) => httpClient.post('bo/emails/create', payload),
  updateAdapterEmail: async (payload) => httpClient.post('bo/emails/update', payload),
  fetchAdapterEmails: async (payload) => httpClient.post('bo/emails/list', payload),
  triggerWorkflowCron: async (payload) => httpClient.post('bo/workflows/run', payload),
  duplicate: async (payload) => httpClient.post('company/duplicate', payload),
  verifyDomainList: async (payload) => httpClient.post('mailer/domain/verify', payload),
  fetchBillingIds: async (payload) => httpClient.post('hubspot/companies/find', payload),
  codatSettingsSet: async (payload) => httpClient.post('bo/codat/settings/set', payload),
  codatSettingsGet: async (payload) => httpClient.post('bo/codat/settings', payload),
  codatPlatformKeysGet: async (payload) => httpClient.post('bo/codat/platform-keys/get', payload),
  codatSupplementalDataGet: async (payload) => httpClient.post('bo/codat/supplemental-data/get', payload),
  codatSupplementalDataSet: async (payload) => httpClient.post('bo/codat/supplemental-data/set', payload),
  getGavitiKeys: async (payload) => httpClient.post('bo/adapters/gaviti-keys/get', payload),
};
