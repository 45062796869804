import { httpClient } from '@api';

import { CustomWidgetConfig } from './custom-widgets-config.dto';

export default {
  list: async ({ companyId }) => {
    const list = await httpClient.get('widget', {
      params: {
        companyId,
      },
    });

    return list.map((item) => new CustomWidgetConfig(item));
  },
  create: async (payload) => httpClient.post('widget', payload),
  update: async ({ id, body }) => httpClient.put(`widget/${id}`, body),
  delete: async ({ id, companyId }) =>
    httpClient.delete(`widget/${id}`, {
      params: {
        companyId,
      },
    }),
  getData: async ({ id, body }) => httpClient.post(`widget/data/${id}`, body),
};
