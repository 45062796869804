import { disputeManagementService } from '@services';

export default {
  setSelected: ({ commit }, selected) => {
    commit('SET_SELECTED', selected);
  },
  async find({ commit }, payload) {
    const { list = [], total = 0 } = await disputeManagementService.find(payload);

    commit('SET_ITEMS', list);
    commit('SET_SERVER_ITEMS_LENGTH', total);
  },
  async update(_, dispute) {
    await disputeManagementService.update(dispute);
  },
  async export(_, payload) {
    return disputeManagementService.find(payload);
  },
  async findOne(_, disputeId) {
    return disputeManagementService.findOne(disputeId);
  },
};
