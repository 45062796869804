import { isNil } from 'lodash-es';

import { LAYOUTS } from '@enums';

export default [
  {
    name: 'email',
    path: '/email',
    meta: {
      title: 'Email',
      layout: LAYOUTS.BASE,
    },
    props: (route) => {
      const { query } = route;
      const { email_token: emailToken } = query;

      return { emailToken: !isNil(emailToken) ? emailToken : null };
    },
    component: () => import('@pages/EmailAnalytics'),
  },
];
