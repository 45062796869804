export default [
  {
    x: 0,
    y: 0,
    w: 2,
    h: 4,
    i: 'totalAR',
  },
  {
    x: 2,
    y: 0,
    w: 2,
    h: 4,
    i: 'customersCreditLimit',
  },
  {
    x: 4,
    y: 0,
    w: 1.6,
    h: 4,
    i: 'dso',
  },
  {
    x: 5.6,
    y: 0,
    w: 1.6,
    h: 4,
    i: 'add',
  },
  {
    x: 7.2,
    y: 0,
    w: 1.6,
    h: 4,
    i: 'mdd',
  },
  {
    x: 8.8,
    y: 0,
    w: 1.6,
    h: 4,
    i: 'collectionRate',
  },
  {
    x: 10.4,
    y: 0,
    w: 1.6,
    h: 4,
    i: 'openInvoices',
  },
  {
    x: 0,
    y: 4,
    w: 3,
    h: 4,
    i: 'closedInvoices',
  },
  {
    x: 3,
    y: 4,
    w: 3,
    h: 4,
    i: 'workflowsActivities',
  },
  {
    x: 6,
    y: 4,
    w: 3,
    h: 4,
    i: 'notesActivities',
  },
  {
    x: 9,
    y: 4,
    w: 3,
    h: 4,
    i: 'manualTimeSaved',
  },
  {
    x: 0,
    y: 8,
    w: 4,
    h: 8,
    i: 'agingBuckets',
  },
  {
    x: 4,
    y: 8,
    w: 4,
    h: 8,
    i: 'openInvoicesAmountChart',
  },
  {
    x: 8,
    y: 8,
    w: 4,
    h: 8,
    i: 'payments',
  },
  {
    x: 0,
    y: 16,
    w: 4,
    h: 8,
    i: 'termsOfPayments',
  },
  {
    x: 4,
    y: 16,
    w: 4,
    h: 8,
    i: 'paymentsHistoryChart',
  },
  {
    x: 8,
    y: 16,
    w: 4,
    h: 8,
    i: 'paymentGatewayChart',
  },
  {
    x: 0,
    y: 24,
    w: 4,
    h: 8,
    i: 'topDebtors',
  },
  {
    x: 4,
    y: 24,
    w: 4,
    h: 8,
    i: 'customersRiskChart',
  },
  {
    x: 8,
    y: 24,
    w: 4,
    h: 8,
    i: 'pastDueCustomers',
  },
  {
    x: 4,
    y: 32,
    w: 4,
    h: 8,
    i: 'disputesChart',
  },
];
