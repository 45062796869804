export default {
  FETCH_COMPANY_ROLES(state, payload) {
    state.companyRoles = payload;
  },
  FETCH_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  FETCH_COMPANY_PERMISSIONS(state, payload) {
    state.companyPermissions = payload;
  },
};
