import { LAYOUTS } from '@enums';
import Vue from 'vue';

export default [
  {
    name: 'list',
    path: '/companies/list',
    meta: {
      title: 'auth.companies',
      layout: LAYOUTS.COMPANIES,
      auth: true,
    },
    component: () => import('@pages/CompaniesPage'),
  },
  {
    name: 'new-company',
    path: '/companies/new',
    meta: {
      title: 'auth.createCompany',
      layout: LAYOUTS.COMPANIES,
      auth: true,
    },
    component: () => import('@components/Companies/NewCompany'),
    beforeEnter(_to, _from, next) {
      if (!Vue.prototype.$can.support()) {
        return next({ path: '/companies/list' });
      }

      return next();
    },
  },
];
