import { keyBy } from 'lodash-es';

export default {
  FETCH_ACTIVITY_LOG_LIST(state, data) {
    state.activityLogData = data;
  },
  FETCH_ACTIVITY_LOG_INVOICES_LIST(state, data) {
    state.activityLogDataInvoices = keyBy(data, 'id');
  },
  FETCH_ACTIVITY_LOG_CUSTOMERS_LIST(state, data) {
    state.activityLogDataCustomers = keyBy(data, 'id');
  },
  RESET_ACTIVITY_LOG_DATA(state) {
    state.activityLogData = {};
    state.activityLogDataInvoices = {};
    state.activityLogDataCustomers = {};
    state.pagesCount = 0;
    state.activityCount = 0;
    state.page = 0;
  },
  SET_PAGES_COUNT(state, data) {
    state.pagesCount = data;
  },
  SET_ACTIVITY_COUNT(state, data) {
    state.activityCount = data;
  },
  SET_PAGE(state, data) {
    state.page = data;
  },
};
