export default {
  companyRoles: ({ companyRoles }) => companyRoles,
  permissions: ({ permissions }) => permissions,
  companyPermissions: ({ companyPermissions }) => companyPermissions,
  lockedPermissions: () => [
    '8d22db96-e0ed-427b-9b15-9fc1518e7df4',
    'a5253931-e6d4-42ef-8dfa-f1a532dda2d5',
    'bdf4d9bb-8cda-422b-9dc6-e0a81f3d3b7d',
    '140ae91b-a701-45c4-9c3b-edb118d9c2f5',
    '4a5fcc8c-4a38-4451-8010-9d3ba948c67f',
    '54ef4b9c-9a55-45c4-9a03-55029ac012dc',
    '6d4dace8-28cc-490a-b7e6-b25d990b73a3',
    '6730644d-14c6-47e5-a4b8-5892b82db40d',
    'b7923462-d60b-4b4f-8211-2c75da7cb3b0',
  ],
};
