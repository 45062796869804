export default {
  list: [],
  total: 0,
  selected: [],
  accountList: [],
  accountIds: [],
  customers: {},
  invoices: {},
  isBankReconnectNeeded: false,
  tableOptions: {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 25,
    multiSort: false,
    mustSort: false,
    page: 1,
    sortBy: ['date'],
    sortDesc: [true],
  },
};
