export const MODES = {
  DEFAULT: 'default',
  WIDE: 'wide',
  FILE: 'file',
};

export const DRAWER_CONFIG = {
  [MODES.DEFAULT]: {
    width: '25vw',
  },
  [MODES.WIDE]: {
    width: '40vw',
  },
  [MODES.FILE]: {
    width: '25vw',
    style: {
      disaply: 'flex',
    },
  },
};
