import { zipObject } from 'lodash-es';

import { companyService, authService } from '@services';
import { httpClient } from '@api';

async function fetchAndCommitData(fetchFunction, type, commit, commitName, companyIds = []) {
  const data = await Promise.all(companyIds.map((companyId) => fetchFunction({ companyId }, type)));
  const dataObject = zipObject(companyIds, data);

  commit(commitName, dataObject);
}

async function fetchInvoiceBL({ companyId, users }, type) {
  return httpClient.post(`/invoices/${type}`, { companyId, users });
}

async function fetchCustomerBL({ companyId, users }, type) {
  return httpClient.post(`/customers/${type}`, { companyId, users });
}

export default {
  addNewCompany: async (_, payload) => {
    try {
      return await companyService.create(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  logSupportReason: async (_, payload) => {
    try {
      return await authService.reason(payload);
    } catch ({ message }) {
      throw new Error(message);
    }
  },
  fetchCompaniesTotalLocalBalance: async ({ commit }, companyIds) => {
    try {
      await fetchAndCommitData(
        fetchInvoiceBL,
        'total-local-balance',
        commit,
        'FETCH_COMPANIES_TOTAL_LOCAL_BALANCE',
        companyIds,
      );
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchCompaniesCountInvoiceOutstanding: async ({ commit }, companyIds) => {
    await fetchAndCommitData(
      fetchInvoiceBL,
      'count-outstanding',
      commit,
      'FETCH_COMPANIES_INVOICE_COUNT_OUTSTANDING',
      companyIds,
    );
  },
  fetchCompaniesCountInvoiceOverdue: async ({ commit }, companyIds) => {
    await fetchAndCommitData(
      fetchInvoiceBL,
      'count-overdue',
      commit,
      'FETCH_COMPANIES_INVOICE_COUNT_OVERDUE',
      companyIds,
    );
  },
  fetchCompaniesAging: async ({ commit }, companyIds) => {
    await fetchAndCommitData(fetchInvoiceBL, 'aging', commit, 'FETCH_COMPANIES_AGING', companyIds);
  },
  fetchCompaniesCountCustomerOverdue: async ({ commit }, companyIds) => {
    await fetchAndCommitData(fetchCustomerBL, 'count-overdue', commit, 'FETCH_CUSTOMER_COUNT_OVERDUE', companyIds);
  },
};
