/* eslint-disable class-methods-use-this */
import { isString, isEqual } from 'lodash-es';
import { EventEmitter } from 'events';

import { loadAsyncScript } from '../utils';

export default class Intercom extends EventEmitter {
  constructor({ appId } = {}) {
    super();

    if (!isString(appId)) return;

    this.appId = appId;
    this.defaultOptions = { app_id: appId };
    this.ready = false;
    this.isBooted = false;
    this.visible = false;
    this.unreadCount = 0;

    this.load();
  }

  load() {
    if (!window || !document) return;

    const load = () => loadAsyncScript(this.appId, () => this.init());

    if (isEqual(document.readyState, 'complete')) loadAsyncScript(this.appId, () => this.init());
    else if (window.attachEvent) window.attachEvent('onload', load);
    else window.addEventListener('load', load, false);
  }

  init() {
    this.ready = true;

    this.callIntercom('onHide', () => {
      this.visible = false;
    });

    this.callIntercom('onShow', () => {
      this.visible = true;
    });

    this.callIntercom('onUnreadCountChange', (unreadCount) => {
      this.unreadCount = unreadCount;
    });

    this.emit('ready');
  }

  boot(options) {
    this.isBooted = true;

    this.callIntercom('boot', { ...this.defaultOptions, ...options });
  }

  callIntercom(...args) {
    if (!window.Intercom) return;

    return window.Intercom(...args);
  }

  shutdown() {
    this.isBooted = false;

    this.callIntercom('shutdown');
  }

  update(...options) {
    this.callIntercom('update', ...options);
  }

  show() {
    this.callIntercom('show');
  }

  hide() {
    this.callIntercom('hide');
  }

  close() {
    this.callIntercom('update', { hide_default_launcher: true });
  }

  showMessages() {
    this.callIntercom('showMessages');
  }

  showNewMessage(content) {
    this.callIntercom('showNewMessage', ...(isString(content) ? [content] : []));
  }

  trackEvent(name, ...metadata) {
    this.callIntercom('trackEvent', ...[name, ...metadata]);
  }

  startTour(id, ...metadata) {
    this.callIntercom('startTour', ...[id, ...metadata]);
  }

  getVisitorId() {
    this.callIntercom('getVisitorId');
  }
}
