import Vue from 'vue';

import { isEmpty } from 'lodash-es';
import { httpClient } from '@api';

import { CreditRequest } from './credit-management.dto';

export default {
  list: async (params) => {
    try {
      let normalizedList = [];

      const { data, total } = await httpClient.post('credit-request/list', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        ...params,
      });

      if (!isEmpty(data)) {
        normalizedList = data.map((item) => new CreditRequest(item));
      }

      return { data: normalizedList, total };
    } catch (error) {
      console.warn(error);
    }
  },
  update: async ({ id, payload }) => {
    try {
      await httpClient.put(`credit-request/${id}`, payload);
    } catch (error) {
      console.warn(error);
    }
  },
  send: async (payload) => {
    try {
      await httpClient.post('credit-request/email', payload);
    } catch (error) {
      console.warn(error);
    }
  },
  report: async ({ creditRequestId }) => {
    try {
      return await httpClient.post(`credit-report/get/${creditRequestId}`, {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
      });
    } catch (error) {
      console.warn(error);
    }
  },
  reportList: async ({ type, ...payload }) => {
    try {
      return await httpClient.post('credit-report/list', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        match: {
          $match: {
            report: { $exists: true, $ne: {} },
          },
        },
        ...payload,
      });
    } catch (error) {
      console.warn(error);
    }
  },
  requestReportCompanies: async ({ type, ...payload }) => {
    return httpClient.post(`credit-report/companies/${type}`, {
      companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
      ...payload,
    });
  },
  requestReport: async ({ type, ...payload }) => {
    return httpClient.post(`credit-report/generate/report/${type}`, {
      companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
      ...payload,
    });
  },
  requestPDFReport: async ({ type, ...payload }) => {
    return httpClient.post(`credit-report/generate/pdf/${type}`, {
      companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
      ...payload,
    });
  },
  updateReport: async ({ creditReportId, ...rest }) => {
    return httpClient.put(`credit-report/${creditReportId}`, {
      companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
      ...rest,
    });
  },
  businessCountriesList: async ({ type }) => {
    return httpClient.post(`credit-report/subscription-details/${type}`, {
      companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
    });
  },
  searchFields: async ({ type, countryCode }) => {
    return httpClient.post(`credit-report/search-criteria/${type}`, {
      companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
      countryCode,
    });
  },
  checkDirectConnection: async ({ type }) => {
    return httpClient.post(`credit-report/check-direct-connection/${type}`, {
      companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
    });
  },
};
