const TAGS = {
  CUSTOMER: {
    INVOICE_REPORT: '{{CUSTOMER.INVOICE.REPORT}}',
    CREDIT_REPORT: '{{CUSTOMER.CREDIT.REPORT}}',
  },
  COMPANY: {
    PAYMENT_INSTRUCTION: '{{COMPANY.PAYMENT_INSTRUCTION}}',
    LOGO: '{{COMPANY.LOGO}}',
  },
  INVOICE: {
    LINK: '{{INVOICE.LINK}}',
  },
};

Object.freeze(TAGS);

export default TAGS;
