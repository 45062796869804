export default {
  isLoading: false,
  customers: [],
  stats: {},
  topCustomers: [],
  overdueCustomers: [],
  countOverdueCustomers: 0,
  percentageOverdueCustomers: 0,
  selected: [],
};
