export default {
  list: ({ list }) => list,
  total: ({ total }) => total,
  selected: ({ selected }) => selected,
  accountList: ({ accountList }) => accountList,
  isBankReconnectNeeded: ({ isBankReconnectNeeded }) => isBankReconnectNeeded,
  accountIds: ({ accountIds }) => accountIds,
  tableOptions: ({ tableOptions }) => tableOptions,
  customers: ({ customers }) => customers,
  invoices: ({ invoices }) => invoices,
};
