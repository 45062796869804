export default [
  /.*(Timeout).*/i,
  /.*(Cloudflare Turnstile).*/i,
  /.*(TurnstileError).*/i,
  /.*('accessControlRoleIds').*/i,
  /.*('clientWidth').*/i,
  /.*('can').*/i,
  /.*('selectedCompany').*/i,
  /.*(answer_invalid).*/i,
  /^\[Cloudflare Turnstile\] answer_invalid\.$/,
];
