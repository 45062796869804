import { customFieldsAttachmentsService } from '@services';

export default {
  async upload(_, { key, type, id, files }) {
    try {
      const formData = new FormData();

      formData.append('key', key);
      formData.append('type', type);
      formData.append('id', id);
      formData.append('companyId', this._vm.$auth.user()?.selectedCompany?.id);

      for (const file of files) {
        formData.append('file', file);
      }

      return await customFieldsAttachmentsService.upload(formData);
    } catch ({ message }) {
      console.error(message);
    }
  },
  async rename(_, payload) {
    try {
      return await customFieldsAttachmentsService.rename({
        companyId: this._vm.$auth.user()?.selectedCompany?.id,
        ...payload,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  async remove(_, payload) {
    try {
      return await customFieldsAttachmentsService.delete({
        companyId: this._vm.$auth.user()?.selectedCompany?.id,
        ...payload,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  async link(_, payload) {
    try {
      return await customFieldsAttachmentsService.link({
        companyId: this._vm.$auth.user()?.selectedCompany?.id,
        ...payload,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
};
