const qbwcTemplate = ({ region, name, token, ownerId, fileId }) => `<?xml version="1.0"?>
<QBWCXML>
  <AppName>GavitiQBWCAdapter</AppName>
  <AppDisplayName>Gaviti QBWC Adapter</AppDisplayName>
  <AppID></AppID>
  <AppURL>https://${region}api.gaviti.com/v2/adapter/run/${name}?token=${token}</AppURL>
  <AppDescription>Gaviti Quick Books Web Connector Adapter</AppDescription>
  <AppSupport>https://gaviti.com/</AppSupport>
  <IsReadOnly>false</IsReadOnly>
  <PersonalDataPref>pdpNotNeeded</PersonalDataPref>
  <UserName>Gaviti</UserName>
  <OwnerID>{${ownerId}}</OwnerID>
  <FileID>{${fileId}}</FileID>
  <QBType>QBFS</QBType>
  <CertURL>http://gaviti.com/</CertURL>
  <Scheduler>
    <RunEveryNMinutes>120</RunEveryNMinutes>
  </Scheduler>
</QBWCXML>
`;

export default qbwcTemplate;
