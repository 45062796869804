const demoCompanyData = {
  timeFilter: {
    closedInvoicesTotalAmount: 6832551,
    workflowsActivities: {
      count: 1023,
      customers: 553,
    },
    notesActivities: {
      count: 743,
      customers: 301,
    },
    manualNotesActivities: {
      count: 274,
      customers: 225,
    },
  },
  dso: 67,
  trends: {
    totalAr: 6,
    countOutstandingInvoices: -4,
    countCustomersOverdue: -7,
    dso: -8,
    dsoStandard: -5,
    add: -9,
    mdd: -10,
    collectionRate: 15,
    customersCreditLimit: 11,
  },
  cei: 84,
  dsoStandard: 89,
  add: 22,
  mdd: 56,
  collectionRate: {
    calculationValues: {
      expectedCollected: 19_000_000,
      collected: 15_000_000,
    },
    value: 80,
  },
  manualTimeSaved: {
    value: 1530,
    startCountDate: 1641765600000,
  },
  customersCreditLimit: 47500000,
};

export default {
  closedInvoicesTotalAmount: ({ closedInvoicesTotalAmount }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany']
      ? demoCompanyData.timeFilter.closedInvoicesTotalAmount
      : closedInvoicesTotalAmount;
  },
  workflowsActivities: ({ workflowsActivities }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.timeFilter.workflowsActivities : workflowsActivities;
  },
  notesActivities: ({ notesActivities }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.timeFilter.notesActivities : notesActivities;
  },
  manualNotesActivities: ({ manualNotesActivities }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.timeFilter.manualNotesActivities : manualNotesActivities;
  },
  dso: ({ dso }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? { value: demoCompanyData.dso } : dso;
  },
  cei: ({ cei }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.cei / 100 : cei / 100;
  },
  dsoStandard: ({ dsoStandard }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? { value: demoCompanyData.dsoStandard } : dsoStandard;
  },
  add: ({ add }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? { value: demoCompanyData.add } : add;
  },
  mdd: ({ mdd }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? { value: demoCompanyData.mdd } : mdd;
  },
  dsoTrend: ({ trends: { dso } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.trends.dso : dso;
  },
  dsoStandardTrend: ({ trends: { dsoStandard } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.trends.dsoStandard : dsoStandard;
  },
  addTrend: ({ trends: { add } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.trends.add : add;
  },
  mddTrend: ({ trends: { mdd } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.trends.mdd : mdd;
  },
  collectionRateTrend: ({ trends: { collectionRate } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.trends.collectionRate : collectionRate;
  },
  totalArTrend: ({ trends: { totalAr } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.trends.totalAr : totalAr;
  },
  customersCreditLimitTrend: ({ trends: { customersCreditLimit } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.trends.customersCreditLimit : customersCreditLimit;
  },
  countOutstandingInvoicesTrend: ({ trends: { countOutstandingInvoices } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany']
      ? demoCompanyData.trends.countOutstandingInvoices
      : countOutstandingInvoices;
  },
  countCustomersOverdueTrend: ({ trends: { countCustomersOverdue } }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.trends.countCustomersOverdue : countCustomersOverdue;
  },
  collectionRate: ({ collectionRate }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.collectionRate : collectionRate;
  },
  manualTimeSaved: ({ manualTimeSaved }, _getters, _rootState, rootGetters) => {
    return rootGetters['auth/isDemoCompany'] ? demoCompanyData.manualTimeSaved : manualTimeSaved;
  },
  outstandingInvoices: ({ outstandingInvoices }) => outstandingInvoices,
  customersCreditLimit: ({ customersCreditLimit }, _getters, _rootState, rootGetters) =>
    rootGetters['auth/isDemoCompany'] ? demoCompanyData.customersCreditLimit : customersCreditLimit,
  loadingWidgets: ({ loadingWidgets }) => loadingWidgets,
  filterWidgets: ({ filterWidgets }) => filterWidgets,
};
