<template>
  <v-fade-transition>
    <component :is="layout" :dark-mode="darkMode">
      <slot />
    </component>
  </v-fade-transition>
</template>

<script>
export default {
  name: 'AppLayout',
  components: {
    AuthLayout: () => import('@layouts/AuthLayout'),
    BaseLayout: () => import('@layouts/BaseLayout'),
    CompaniesLayout: () => import('@layouts/CompaniesLayout'),
    MainLayout: () => import('@layouts/MainLayout'),
  },
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layout: 'BaseLayout',
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler({ meta }) {
        this.layout = meta.layout;
      },
    },
  },
};
</script>
