export default {
  companyData: {},
  settings: {},
  companyUsers: [],
  companyApaUsers: [],
  companySettings: {},
  merchants: [],
  loadingStatus: 0,
  workflowConfigurationsCheck: {},
  showLogs: false,
  modal: {
    open: false,
    title: undefined,
    contentType: undefined,
  },
  companyAuditList: [],
  companyAuditStats: {},
  isExportLoading: false,
};
