import { cloneDeep } from 'lodash-es';
import { i18n } from '@plugins';

export default {
  users: ({}, _, rootGetters) => {
    const {
      settings: { companyUsers },
    } = rootGetters;

    const users = cloneDeep(companyUsers);

    return users
      .filter(
        (user) =>
          user.accessControlRoleIds.length > 1 ||
          !user.accessControlRoleIds.includes('4976a37a-471d-4467-ae46-eec7f104cd59'),
      )
      .map((user) => {
        if (!user.activeInCompany) {
          user.name = `${user.name} (${i18n.t('workflow.itemInactive')})`;
        }

        return user;
      });
  },
};
