<template>
  <div id="app">
    <v-fade-transition>
      <component :is="layout" :dark-mode="darkMode">
        <router-view :key="$route.fullPath" />
      </component>
    </v-fade-transition>
  </div>
</template>

<script>
import { enUS, he, fr, pt, es } from 'date-fns/locale';
import { mapActions, mapGetters } from 'vuex';
import { isNil, isEqual } from 'lodash-es';

import { DEVICE, LAYOUTS } from '@enums';

import AppLayout from '@layouts/AppLayout';

export default {
  name: 'App',
  components: {
    AppLayout,
  },
  data() {
    return {
      loading: true,
      layout: 'AppLayout',
    };
  },
  methods: {
    ...mapActions('app', ['toggleDeviceOrientation', 'toggleIsDeviceMobile']),
    ...mapActions('settings', ['fetchCompanySettings']),
    toggleDarkMode() {
      this.$vuetify.theme.dark = this.darkMode;
    },
    getOrientation({ orientation, innerWidth }) {
      switch (orientation) {
        case 90:
        case -90:
          this.toggleDeviceOrientation(DEVICE.ORIENTATION.LANDSCAPE);

          break;
        default:
          this.toggleDeviceOrientation(DEVICE.ORIENTATION.PORTRAIT);

          break;
      }

      this.$forceUpdate();
      this.toggleIsDeviceMobile(innerWidth < this.$vuetify.breakpoint.mobileBreakpoint);
    },
    deviceOrientationHandler(event) {
      this.getOrientation(event.target.window);
    },
    getUserLocale() {
      if (!isNil(this.$auth.user())) {
        this.$i18n.locale = this.$auth.user().settings?.language || 'en';

        if (isEqual(this.$i18n.locale, 'he')) {
          this.$vuetify.rtl = true;
          this.$root.$dateFnsLocale = he;
        } else if (isEqual(this.$i18n.locale, 'fr')) {
          this.$root.$dateFnsLocale = fr;
        } else if (isEqual(this.$i18n.locale, 'pt')) {
          this.$root.$dateFnsLocale = pt;
        } else if (isEqual(this.$i18n.locale, 'es')) {
          this.$root.$dateFnsLocale = es;
        } else {
          this.$root.$dateFnsLocale = enUS;
        }
      }
    },
  },
  computed: {
    ...mapGetters('app', ['darkMode', 'isAppLoading']),
    isProduction() {
      return isEqual(import.meta.env.MODE, 'production');
    },
  },
  created() {
    this.toggleDarkMode();

    this.loading = !this.$auth.ready();
  },
  async mounted() {
    this.loading = !this.$auth.ready();

    this.$nextTick(() => {
      window.addEventListener('resize', this.deviceOrientationHandler, false);
      window.addEventListener('orientationchange', this.deviceOrientationHandler, false);

      this.getOrientation(window);
      this.getUserLocale();
    });

    if (this.isProduction && !isNil(this.$auth.user())) {
      await this.$clarity.identify(this.$auth.user().email);
    }
  },
  updated() {
    this.$nextTick(() => this.getUserLocale());
    this.toggleDarkMode();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.deviceOrientationHandler, false);
    window.removeEventListener('orientationchange', this.deviceOrientationHandler, false);
  },
  watch: {
    layout: {
      handler() {
        this.layout = this.loading || this.isAppLoading ? LAYOUTS.LOADING : LAYOUTS.APP;
      },
      immediate: false,
      deep: false,
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0 !important;

  .toasted-alert {
    font-family: Roboto, sans-serif !important;

    .action.ripple {
      color: #fff !important;
    }
  }

  .gav-rtl {
    direction: rtl;
  }

  .gav-ltr {
    direction: ltr;
  }

  .underline-distance {
    text-underline-offset: 2px;
  }

  .no-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: rgba(0, 0, 0, 0.45);
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0, 0, 0, 0.61);
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: rgba(0, 0, 0, 0.45);
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  ::-webkit-scrollbar-thumb:horizontal:active {
    background: rgba(0, 0, 0, 0.61);
    -webkit-border-radius: 0;
    border-radius: 0;
  }

  .v-menu__content {
    border-radius: 0 !important;
    background: #ffffff;

    > .v-list {
      padding: 0 !important;
    }
  }

  .v-data-footer > .v-data-footer__select {
    height: 2.8rem !important;
  }

  .no-drop-shadow > .v-input__control > .v-input__slot {
    box-shadow: none !important;
  }

  .no-border {
    border: none !important;
  }

  .spaced {
    .v-input {
      &__slot {
        &:hover {
          background: rgb(224, 224, 224) !important;
        }

        &:before {
          border: none !important;
        }
      }
    }
  }

  .spaced.right {
    .v-input {
      &__icon--prepend-inner {
        padding-right: 1.75em !important;
        padding-left: 2.5rem !important;
      }

      &__icon--append {
        padding-left: 1.5rem !important;
      }
    }
  }

  .spaced.left {
    .v-input {
      &__icon--prepend-inner {
        padding-left: 1.75em !important;
        padding-right: 2.5rem !important;
      }

      &__icon--append {
        padding-right: 1.5rem !important;
      }
    }
  }

  table {
    .v-input--selection-controls__ripple {
      top: calc(50% - 22px) !important;
    }
  }

  .vertical-divider-inset {
    height: 2rem !important;
    margin: auto -1px !important;
  }

  div.v-date-picker-table > table > tbody > tr > td {
    padding: 0 !important;
  }

  .custom-chip {
    padding: 6px !important;
    height: auto !important;
  }

  span.v-chip:hover::before {
    opacity: 0 !important;
  }

  .drawer-shadow {
    z-index: 8;
    box-shadow:
      0 8px 10px -5px rgb(0 0 0 / 20%),
      0 16px 24px 2px rgb(0 0 0 / 14%),
      0 6px 30px 5px rgb(0 0 0 / 12%);
  }

  .text-h6 {
    line-height: 1.752rem !important;
  }

  .v-data-footer {
    margin-right: unset !important;
    margin-left: unset !important;
    justify-content: center !important;

    &__select {
      margin-right: unset !important;
      margin-left: unset !important;
    }
  }

  #custom-expansion-panel {
    .v-expansion-panel {
      &-content {
        &__wrap {
          padding: 0 !important;
        }
      }
    }
  }

  .disabled-row {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  .chart-additional-text {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 12px;
  }

  .trend--text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--v-secondary-lighten1);
  }

  .relative {
    position: relative;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .start-col-custom-width {
    max-width: fit-content;
  }

  .side-drawer-card {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1) !important;
  }
  .side-drawer-tooltip {
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.48) !important;
  }
}
</style>
