import { isNil, isEqual } from 'lodash-es';
import Vue from 'vue';

import { customerService } from '@services';

const getPercentageCustomersOverdue = (countOverdue, totalCount) => {
  try {
    return totalCount > 0 ? countOverdue / totalCount : 0;
  } catch ({ message }) {
    console.error(message);
  }
};

export default {
  fetchCustomers: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);

      const { list, stats } = await customerService.list(payload);

      commit('SET_CUSTOMERS', list);
      commit('SET_STATS', stats);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  findCustomers: async ({}, payload) => {
    try {
      return await customerService.find(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  customersAddUpdateField: async ({}, payload) => {
    try {
      await customerService.customersAddUpdateField(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  enableCustomerWorkflow: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);
      await customerService.enableWorkflow(payload);

      commit('UPDATE_CUSTOMER_WORKFLOW_ENABLED', payload);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  updateCustomers: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);

      await customerService.update(payload);
    } catch ({ message }) {
      if (isEqual(message, 'mismatch permissions')) {
        throw new Error(message);
      } else {
        console.error(message);
      }
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async updateManyCustomers({ commit }, payload) {
    try {
      commit('SET_IS_LOADING', true);

      await customerService.updateMany(payload);
    } catch ({ message }) {
      if (isEqual(message, 'mismatch permissions')) {
        throw new Error(message);
      } else {
        console.error(message);
      }
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  fetchCustomersMetrics: async ({ commit }, payload) => {
    try {
      const { list } = await customerService.list(payload);

      if (!isNil(list)) {
        switch (payload.type) {
          case 'top':
            commit('SET_TOP_CUSTOMERS', list);

            break;
          case 'overdue':
            commit('SET_OVERDUE_CUSTOMERS', list);

            break;
          default:
            throw new TypeError(`The type '${payload.type}' is not a supported customer widget!`);
        }
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchCountOverdueCustomers: async ({ commit }, payload) => {
    try {
      const [
        countOverdue,
        {
          stats: { totalCount },
        },
      ] = await Promise.all([customerService.fetchCountOverdue(payload), customerService.list(payload)]);

      commit('SET_COUNT_OVERDUE_CUSTOMERS', countOverdue);
      commit('SET_PERCENTAGE_OVERDUE_CUSTOMERS', getPercentageCustomersOverdue(countOverdue, totalCount));
    } catch ({ message }) {
      console.error(message);
    }
  },
  downloadReport: async (_, payload) => {
    try {
      return await customerService.list(payload);
    } catch (error) {
      console.error(error);
    }
  },
  fetchCustomer: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);

      const { list } = await customerService.list(payload);

      return list;
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  setSelected: ({ commit }, payload) => {
    commit('SET_SELECTED', payload);
  },
  fetchCustomersIdsWithOpenInvoices: async ({ commit }, { companyId, skip, limit, search }) => {
    try {
      commit('SET_IS_LOADING', true);

      const { customers, total } = await customerService.fetchCustomersIdsWithOpenInvoices({
        companyId,
        skip,
        limit,
        search,
      });

      return { customers, total };
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  fetchCustomersIds: async ({ commit }, { companyId, skip, limit, search, sortBy, sort }) => {
    try {
      commit('SET_IS_LOADING', true);

      const { customers, total } = await customerService.fetchCustomersIds({
        companyId,
        skip,
        limit,
        search,
        sortBy,
        sort,
      });

      return { customers, total };
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  countCustomers: async ({}, payload) => {
    try {
      const { list } = await customerService.list(payload);

      return list?.length || 0;
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchNonExistingCustomerIds: async (_, payload) => {
    try {
      return await customerService.getNonExistingIds(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  deleteCustomers: async ({}, payload) => {
    try {
      await customerService.delete({ companyId: Vue.auth.user()?.selectedCompany?.id, ...payload });
    } catch ({ message }) {
      console.error(message);
    }
  },
  async fetchCustomerInsights({}, payload) {
    return customerService.fetchCustomerInsights(payload);
  },
  async rateInsight({}, payload) {
    return customerService.rateInsight(payload);
  },
};
