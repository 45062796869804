import { httpClient } from '@api';

export default {
  fetchCompanyRoles: async (payload) => httpClient.post('access-control/company/roles', payload),
  fetchCompanyPermissions: async (payload) => httpClient.post('access-control/company/permissions', payload),
  fetchPermissions: async (payload) => httpClient.post('access-control/permissions', payload),
  createCompanyRole: async (payload) => httpClient.post('access-control/company/role/create', payload),
  updateCompanyRole: async (payload) => httpClient.post('access-control/company/role/update', payload),
  deleteCompanyRole: async (payload) => httpClient.post('access-control/company/role/delete', payload),
};
