import router from '@router';
import { authService } from '@services';

import { httpClient } from '@api';

export default {
  setUser({ commit }, payload) {
    commit('SET_USER', payload);
  },
  ssoFetchEntryPoint: async (_, payload) => {
    try {
      return await authService.fetchSsoEntryPoint(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  ssoLogin: async (_, payload) => {
    try {
      return await authService.ssoLogin(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  setSecurityPolicy: ({ commit }, payload) => {
    commit('SET_SECURITY_POLICY', payload);
  },
  logout({ state }) {
    const { securityPolicy } = state;
    const user = this._vm.$auth.user();

    this._vm.$VueAnalytics.track({
      event_uuid: '02c96f21-c7e2-48e8-ac2f-d1b80f114276',
      type: 'header',
      action: 'clicked',
      element: 'button',
      category: 'general_action',
      label: 'sign_out',
    });

    if (!user) return;

    const { id: userId, ssoEnabled, securityPolicyId } = user;
    const SECURITY_POLICY_ID = securityPolicy[userId] || securityPolicyId;
    const redirect = ssoEnabled ? `/login/sso?securityPolicyId=${SECURITY_POLICY_ID}` : '/login';

    try {
      this._vm.$intercom.shutdown();

      httpClient.post('/auth/logout');

      this._vm.$auth.logout().then(() => {
        const keys = [
          'GAV_accessToken',
          'GAV_accessTokenExpiresAt',
          'GAV_refreshToken',
          'GAV_refreshTokenExpiresAt',
          'GAV-company-id',
        ];

        keys.forEach((key) => {
          localStorage.removeItem(key);
        });
      });
    } catch ({ message }) {
      console.error(`Error while logging out: ${message}`);
    } finally {
      router.push(redirect);

      this.reset();

      this._vm.$http.defaults.headers.common.Authorization = null;
      this._vm.$http.defaults.headers.common['GAV-company-id'] = null;
    }
  },
};
