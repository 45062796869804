import { load } from 'webfontloader';

const loadFonts = async () => {
  const prefetch = document.createElement('link');
  prefetch.rel = 'dns-prefetch';
  prefetch.href = '//fonts.googleapis.com';
  document.head.appendChild(prefetch);

  const preconnect = document.createElement('link');
  preconnect.rel = 'preconnect';
  preconnect.href = 'https://fonts.gstatic.com';
  document.head.appendChild(preconnect);

  const WebFontConfig = {
    google: {
      families: ['Roboto:100,300,400,500,700,900&display=swap'],
    },
    active: () => {
      sessionStorage.fonts = true;
    },
  };

  load(WebFontConfig);
};

export default loadFonts;
