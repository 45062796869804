import { httpClient } from '@api';

export default {
  async list(payload) {
    try {
      const res = httpClient.post('rates/list', payload);

      return res;
    } catch (err) {
      console.warn(err);
    }
  },
};
