export default {
  presentNotification: async ({ commit, dispatch }, payload) => {
    try {
      commit('SET_NOTIFICATION', {
        type: payload.type,
        visible: payload.visible,
        message: payload.message,
        timeoutInSeconds: payload.timeoutInSeconds || 7000,
        maxWidth: payload.maxWidth,
        withCopy: payload.withCopy,
      });
      commit('INCREASE_NOTIFICATINS_COUNTER', 1);
    } catch ({ message }) {
      console.error(message);
    } finally {
      await dispatch('dismissNotificationsTimeout');
    }
  },
  dismissNotification: ({ commit }) => {
    commit('SET_NOTIFICATION', {
      type: 'success',
      visible: false,
      message: undefined,
      timeoutInSeconds: 0,
    });
  },
  dismissNotificationsTimeout: async ({ commit, state, dispatch }) => {
    const {
      notification: { timeoutInSeconds },
    } = state;

    await setTimeout(() => {
      commit('DECREASE_NOTIFICATINS_COUNTER', 1);

      if (state.notificationsCount === 0) {
        dispatch('dismissNotification');
      }
    }, timeoutInSeconds);
  },
};
