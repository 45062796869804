import Vue from 'vue';
import { get, find, isEqual, isNil, isEmpty, sum, cloneDeep } from 'lodash-es';

import { i18n, vuetify } from '@plugins';
import { formatBigNumbers } from '@utils/numbers';
import { formatCurrencyBigNumbers } from '@utils/money';

import { CustomWidget } from '@services/custom-widgets/custom-widgets.dto';

import actions from './actions';

const { teal } = vuetify.framework.theme.themes.light;
const MAX_LABELS = 9;

const transformArray = (arr) => {
  if (arr.length <= MAX_LABELS + 1) {
    return arr;
  }

  const first = cloneDeep(arr).slice(0, MAX_LABELS);
  const sumOfRest = cloneDeep(arr)
    .slice(MAX_LABELS)
    .reduce((acc, val) => acc + val, 0);
  first.push(sumOfRest);
  return first;
};

const buildChart = ({
  type,
  title,
  calculation,
  mode,
  customField,
  filterField,
  dataSource,
  excludeEmpty,
  search = {},
  series = [],
  labels = [],
  amounts = [],
}) => {
  const { currency } = Vue.auth.user().selectedCompany;
  const isSumChart = isEqual(dataSource, 'invoices') && isEqual(calculation, 'sum');

  const otherLabels = cloneDeep(labels).slice(MAX_LABELS);
  const otherSeries = cloneDeep(series).slice(MAX_LABELS);
  const otherAmounts = cloneDeep(amounts).slice(MAX_LABELS);

  if (!isEmpty(otherLabels) && !isEqual(otherLabels.length, 1)) {
    labels = cloneDeep(labels).slice(0, MAX_LABELS);
    labels.push('other');
  }

  series = transformArray(series);
  amounts = transformArray(amounts);

  const chart = {
    type,
    title,
    options: {
      theme: {
        mode,
      },
      chart: {
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 350,
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        background: 'transparent',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          dataPointMouseEnter(event) {
            event.target.style.cursor = 'pointer';
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -8,
        style: {
          fontSize: '0.765rem',
        },
        background: {
          enabled: true,
          foreColor: isEqual(mode, 'dark') ? '#000' : '#fff',
          opacity: 0,
          dropShadow: {
            enabled: false,
          },
        },
      },
      tooltip: {
        theme: mode,
        fillSeriesColor: false,
        custom: ({ dataPointIndex, seriesIndex, w }) => {
          const label = isEqual(type, 'bar') ? labels[dataPointIndex] : labels[seriesIndex];
          const count = isEqual(type, 'bar') ? series[dataPointIndex] : series[seriesIndex];
          const amount = isEqual(type, 'bar') ? amounts[dataPointIndex] : amounts[seriesIndex];

          if (isEqual(label, 'other')) {
            return `
            ${
              isEqual(type, 'donut')
                ? `<div>
                <span class="apexcharts-legend-marker" style="
                  background: ${w.globals.colors[seriesIndex]} !important;
                  height: 12px;
                  width: 12px;
                  left: 0px;
                  top: 0px;
                  margin-top: 10px;
                  margin-right: 10px;
                  margin-left: 10px;
                  border-width: 0px;
                  border-radius: 12px;" />
              </div>`
                : ''
            }

              <div style="margin-left: ${isEqual(type, 'donut') ? '30px' : '10px'}; margin-top: ${
                isEqual(type, 'donut') ? '-22px' : '10px'
              }; margin-bottom: ${isEqual(dataSource, 'customers') ? '10px' : '-4px'}; margin-right: 10px;">
                <span style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: ${
                  isEqual(dataSource, 'customers') ? 'normal' : 'bold'
                }">
                  ${label}
                </span>
  
                ${
                  isEqual(calculation, 'count')
                    ? `<span style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; margin-left: 20px; font-weight: bold;">
                        ${count}
                      </span>`
                    : `<span style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; margin-left: 20px; font-weight: bold;">
                        ${formatCurrencyBigNumbers(amount, currency)}
                      </span>`
                }
              </div>

              <hr style="margin: 10px; color: #9E9E9E; height: 0.5px;" />
  
              ${otherLabels
                .map(
                  (label, index) =>
                    `<div style="margin: 10px; margin-bottom: 0px; margin-top: 0px"><span style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${label}</span>${
                      isEqual(calculation, 'count')
                        ? `<span style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; margin-left: 20px; font-weight: bold;">${otherSeries[index]}</span>`
                        : `<span style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; margin-left: 20px; font-weight: bold;">${formatCurrencyBigNumbers(
                            otherAmounts[index],
                            currency,
                          )}</span>`
                    }</div>`,
                )
                .join('')}
              `;
          }

          return `
            ${
              isEqual(type, 'donut')
                ? `<div>
                <span class="apexcharts-legend-marker" style="
                  background: ${w.globals.colors[seriesIndex]} !important;
                  height: 12px;
                  width: 12px;
                  left: 0px;
                  top: 0px;
                  margin-top: 10px;
                  margin-right: 10px;
                  margin-left: 10px;
                  border-width: 0px;
                  border-radius: 12px;" />
              </div>`
                : ''
            }
  
              <div style="margin-left: ${isEqual(type, 'donut') ? '30px' : '10px'}; margin-top: ${
                isEqual(type, 'donut') ? '-22px' : '10px'
              }; margin-bottom: ${isEqual(dataSource, 'customers') ? '10px' : '-4px'}; margin-right: 10px;">
                <span style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: ${
                  isEqual(dataSource, 'customers') ? 'normal' : 'bold'
                }">
                  ${label}
                </span>
  
                ${
                  isEqual(dataSource, 'customers')
                    ? `<span style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; margin-left: 20px; font-weight: bold;">
                        ${count}
                      </span>`
                    : ''
                }
              </div>
  
              ${
                isEqual(dataSource, 'invoices')
                  ? `
                  <hr style="margin: 10px; color: #9E9E9E; margin-bottom: 0px; height: 0.5px;" />
  
                    <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                      <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px; font-weight: bold;">
                        <div class="apexcharts-tooltip-y-group px-1">
                          <span class="apexcharts-tooltip-text-value">
                            <span style="vertical-align: middle; font-weight: normal;">
                              ${`# ${i18n.t(`nav.${dataSource}`)}`}:
                            </span>
  
                            <span style="vertical-align: middle;">
                              ${count}
                            </span>
  
                            <br />
  
                            <span style="vertical-align: middle; font-weight: normal;">
                              Total Amount:
                            </span>
  
                            <span style="vertical-align: middle;">
                              ${formatCurrencyBigNumbers(amount, currency)}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>`
                  : ''
              }`;
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.05,
          inverseColors: true,
          type: 'vertical',
          opacityFrom: 0.95,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      labels,
      otherLabels,
    },
    series: [],
  };

  switch (type) {
    case 'bar':
      if (isEqual(calculation, 'sum')) {
        chart.options.dataLabels.formatter = (val) => formatCurrencyBigNumbers(val, currency);
      }

      chart.series = [{ name: calculation, data: isEqual(calculation, 'sum') ? amounts : series }];
      chart.options = {
        ...chart.options,
        yaxis: {
          labels: {
            formatter: isEqual(calculation, 'sum') ? (val) => formatCurrencyBigNumbers(val, currency) : undefined,
          },
        },
        legend: {
          position: 'bottom',
          markers: {
            radius: 12,
          },
        },
      };

      break;
    case 'donut':
      chart.series = isEqual(calculation, 'sum') ? amounts : series;
      chart.options = {
        ...chart.options,
        grid: {
          padding: {
            top: 10,
            bottom: 5,
          },
        },
        dataLabels: {
          formatter: (val) => `${Math.round(val)}%`,
          dropShadow: {
            enabled: false,
          },
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
          active: {
            filter: {
              type: 'none',
            },
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontWeight: 700,
                  fontSize: '40',
                  color: isEqual(mode, 'dark') ? '#fff' : teal,
                  offsetY: 5,
                  formatter: () =>
                    isEqual(calculation, 'sum')
                      ? formatCurrencyBigNumbers(sum(amounts), currency)
                      : formatBigNumbers(sum(series)),
                },
                value: {
                  show: true,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 400,
                  fontSize: '14',
                  color: isEqual(mode, 'dark') ? '#fff' : teal,
                  formatter: () => i18n.t('dashboard.total'),
                },
                total: {
                  show: true,
                  showAlways: true,
                  fontFamily: 'Roboto, Heebo, sans-serif',
                  fontWeight: 700,
                  fontSize: '40',
                  color: isEqual(mode, 'dark') ? '#fff' : teal,
                  label: Number(sum(series)),
                  formatter: () => i18n.t('dashboard.total'),
                },
              },
            },
          },
        },
      };

      break;
    default:
      console.error('Unknown chart type');

      break;
  }

  if (isEmpty(series)) {
    chart.options = {
      noData: {
        text: 'No records found',
        offsetY: -12,
        style: {
          fontSize: '1.25rem',
          fontFamily: 'Roboto, Heebo, sans-serif',
          color: '#757575',
        },
      },
      grid: {
        show: false,
      },
      yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    };
  }

  const path = `/li/${dataSource}`;
  const getFilterName = () => {};
  const getFilterAdditionalValue = actions.getFilterAdditionalValue;

  const query = {};

  query.customField = customField;

  if (!isNil(filterField)) {
    query.filterField = filterField;
  }

  if (!isNil(excludeEmpty)) {
    query.excludeEmpty = excludeEmpty;
  }

  Object.assign(chart, {
    path,
    filters: {
      search,
      query,
    },
    getFilterName,
    getFilterAdditionalValue,
  });

  return chart;
};

export default {
  customWidgets: ({ customWidgets }, { customWidgetData }) => {
    return customWidgets.reduce((acc, item) => {
      const data = new CustomWidget(item);

      if (isEqual(item?.dataSource, 'customers') || isEqual(item?.filterField, 'allTime')) {
        data.withTopDateSelector = false;
      }

      if (item?.isNew) {
        data.isNew = item.isNew;
      }

      data.chart = customWidgetData(item.id);

      acc[item.id] = data;

      return acc;
    }, {});
  },
  customWidgetsList: ({ customWidgets }) => customWidgets,
  customWidgetData:
    ({ customWidgets, chartData }, _, rootGetters) =>
    (id) => {
      const {
        app: { darkMode },
      } = rootGetters;

      const widget = find(customWidgets, (item) => isEqual(item.id, id));
      const { series = [], labels = [], amounts = [] } = get(chartData, id) || {};

      return buildChart({
        type: widget.type,
        title: widget.title,
        calculation: widget.calculation,
        dataSource: widget.dataSource,
        mode: darkMode ? 'dark' : 'light',
        filterField: isEqual(widget.filterField, 'allTime') ? null : widget.filterField,
        customField: widget.customField,
        excludeEmpty: widget?.excludeEmpty,
        search: widget.search,
        series,
        labels,
        amounts,
      });
    },
};
