import { isUndefined } from 'lodash-es';
import VueIntercom from './plugin';
import Intercom from './lib';

const install = VueIntercom.install;

VueIntercom.install = (Vue, options) => {
  install.call(VueIntercom, Vue, {
    ...options,
  });
};

if (!isUndefined(window) && window.Vue) {
  window.Vue.use(VueIntercom);
}

export default VueIntercom;

export { Intercom };
