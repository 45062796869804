const COMPANY = {
  TYPES: {
    SANDBOX: 'sandbox',
    PRODUCTION: 'production',
    DEMO: 'demo',
  },
};

Object.freeze(COMPANY);

export default COMPANY;
