export function formatBigNumbers(rawNumber) {
  if (rawNumber === undefined || rawNumber === null) {
    return 0;
  }

  if (rawNumber >= 100000) {
    const unitCodes = ['', 'K', 'M', 'B', 'T'];
    const order = Math.floor(Math.log(rawNumber) / Math.log(1000));
    const unitCode = unitCodes[order];
    const num = Math.floor(rawNumber / 1000 ** order);

    return `${num}${unitCode}`;
  }

  return rawNumber.toLocaleString();
}
