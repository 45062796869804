import { Dispute } from '@services/dispute-management/dispute.dto';

export default {
  SET_ITEMS(state, disputes = []) {
    state.items = disputes.map((dispute) => new Dispute(dispute));
  },
  SET_SERVER_ITEMS_LENGTH(state, total = 0) {
    state.serverItemsLength = total;
  },
  SET_SELECTED(state, selectedDispute) {
    if (!selectedDispute) {
      state.selected = [];

      return;
    }

    const selectedDisputeIndex = state.selected.findIndex(({ id }) => id === selectedDispute.id);

    if (selectedDisputeIndex !== -1) {
      state.selected.splice(selectedDisputeIndex, 1);
    } else {
      state.selected.push(selectedDispute);
    }
  },
};
