import { isNil, isEqual } from 'lodash-es';
import VueRouter from 'vue-router';
import Vue from 'vue';

import { LAYOUTS } from '@enums';
import { i18n } from '@plugins';
import store from '@store';

import { authRoutes, mainRoutes, settingsRoutes, companiesRoutes, emailAnalyticsRoutes } from './routes';

Vue.use(VueRouter);

const routes = [
  {
    name: 'index',
    path: '/',
    redirect: '/login',
  },
  ...authRoutes,
  {
    name: '404',
    path: '*',
    meta: {
      title: 'Page Not Found',
      layout: LAYOUTS.BASE,
    },
    component: () => import('@views/NotFound'),
  },
  {
    path: '/li',
    redirect: 'li/dashboard',
    meta: {
      auth: true,
    },
  },
  ...mainRoutes,
  {
    name: 'settings',
    path: '/li/settings',
    redirect: '/li/settings/company-preferences',
    meta: {
      auth: true,
    },
  },
  ...settingsRoutes,
  {
    name: 'li-404',
    path: '/li/*',
    meta: {
      title: 'Page Not Found',
      layout: LAYOUTS.MAIN,
      auth: true,
    },
    component: () => import('@views/NotFound'),
  },
  {
    name: 'companies',
    path: '/companies',
    title: 'Companies',
    redirect: 'companies/list',
    meta: {
      auth: true,
    },
  },
  ...companiesRoutes,
  ...emailAnalyticsRoutes,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (isNil(to.meta.title) && isNil(from.meta.title)) {
    document.title = `${Vue.prototype.$app?.name} | ${i18n.t(from.meta.title)}`;
  }

  if (!isNil(to.meta.title)) {
    document.title = `${Vue.prototype.$app?.name} | ${i18n.t(to.meta.title)}`;
  }

  if (!isNil(to.query.darkMode) && isEqual(to.query.darkMode, 'true')) {
    store.dispatch('app/toggleDarkMode');
  }

  return next();
});

Vue.router = router;

export default router;
