import { isEqual } from 'lodash-es';
import { DEVICE } from '@enums';

export default {
  lastDataUpdate: ({ lastDataUpdate }) => lastDataUpdate,
  isDeviceMobile: ({ isDeviceMobile }) => isDeviceMobile,
  isLandscape: ({ deviceOrientation }) => isEqual(deviceOrientation, DEVICE.ORIENTATION.LANDSCAPE),
  sidebar: ({ sidebar }) => sidebar,
  menuButton: ({ menuButton }) => menuButton,
  appNotificationsCount: ({ appNotificationsCount }) => appNotificationsCount,
  appNotifications: ({ appNotifications }) => appNotifications,
  currentPage: ({ currentPage }) => currentPage,
  pagesCount: ({ pagesCount }) => pagesCount,
  showModal: ({ showModal }) => showModal,
  showAppDrawer: ({ showAppDrawer }) => showAppDrawer,
  appDrawerMode: ({ appDrawerMode }) => appDrawerMode,
  appDrawerTitle: ({ appDrawerTitle }) => appDrawerTitle,
  customers: ({ customers }) => customers,
  invoices: ({ invoices }) => invoices,
  isAppNotificationsLoading: ({ isAppNotificationsLoading }) => isAppNotificationsLoading,
  accessControl: ({ accessControl }) => accessControl,
  darkMode: ({ darkMode }) => darkMode,
  showConfetti: ({ showConfetti }) => showConfetti,
  isAppLoading: ({ isAppLoading }) => isAppLoading,
};
