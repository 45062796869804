import { ratesService } from '@services';

export default {
  fetchRates: async ({ commit }, payload) => {
    try {
      const list = await ratesService.list(payload);

      commit('SET_RATES', {
        rate: payload.rate,
        list,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
};
