export class ActivityLog {
  constructor(objInit) {
    this._id = objInit._id;
    this.actions = objInit.actions ?? [];
    this.attachments = objInit.attachments ?? [];
    this.customerId = objInit.customerId;
    this.eventsCount = objInit.eventsCount ?? 0;
    this.invoiceId = objInit.invoiceId;
    this.isEmailOnDemand = objInit.isEmailOnDemand;
    this.isTest = objInit.isTest;
    this.provider = objInit.provider;
    this.providerId = objInit.providerId;
    this.template = objInit.template;
    this.timestamp = objInit.timestamp;
    this.type = objInit.type;
    this.userId = objInit.userId;
    this.workflow = objInit.workflow;
    this.lastEditedAt = objInit.lastEditedAt;
    this.lastEditorUserId = objInit.lastEditorUserId;
    this.prePaymentBalance = objInit.prePaymentBalance;

    Object.assign(this, objInit);
  }
}
