import { subDays, subMonths, addMonths, startOfYear, format, startOfMonth, endOfMonth, addDays } from 'date-fns';
import { isEmpty, isEqual, isNil, sortBy } from 'lodash-es';
import { es, he } from 'date-fns/locale';
import Vue from 'vue';

import { invoiceService, customerService, companyService, bankReconciliationService } from '@services';
import { AGING_BUCKETS } from '@enums';
import { i18n } from '@plugins';

const { UPCOMING, OVERDUE, DAY_FROM } = AGING_BUCKETS;

const DAYS_TO_SUBTRACT_FOR_UPCOMING = -36500;

const now = new Date();

const getUserLocale = () => {
  const locales = { es, he };

  return locales[i18n.locale];
};

const getMonthYearLabel = (date) => {
  return format(new Date(`${date}-15`), 'MMM yy', {
    locale: getUserLocale(),
  });
};

const dateToUtc = (timestamp) => {
  const date = new Date(timestamp);

  return Date.UTC(date.getUTCFullYear(), date.getMonth(), date.getDate());
};

const startOfDayUtc = Date.UTC(now.getUTCFullYear(), now.getMonth(), now.getDate());

const endOfDayUTC = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 1) - 1;

const endOfDay = (dateUTC) => {
  const date = new Date(dateUTC);

  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 1) - 1;
};

const ignoreTimezoneOffsetEndOfDay = (date) => {
  return new Date(new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000)).setHours(
    23,
    59,
    59,
    59,
  );
};

const demoPercentage = {
  totalAR: 9,
  totalOverdue: -12,
  overdueCustomers: -14,
  outstanding: 10,
  overdue: -12,
  agingBuckets: [9, -10, -11, -9, -10],
};

const getPaymentForecastDates = () => {
  const dates = [];
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  for (let i = 0; i < 3; i += 1) {
    const date = new Date(currentYear, currentMonth + i);

    dates.push({
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    });
  }

  return dates;
};

const demoPaymentGatewayChart = () => {
  const data = [
    { amount: 6800000, count: 600 },
    { amount: 4100000, count: 442 },
    { amount: 3900000, count: 410 },
    { amount: 2300000, count: 350 },
  ];
  const date = new Date();

  return data.map((point, index) => {
    const currentDate = subMonths(date, index);
    return {
      dateParts: {
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      },
      ...point,
    };
  });
};

const demoEmailStatusMetricsChart = () => {
  return {
    totalCustomers: 999_999,
    emailsSent: 9_999,
    events: {
      open: 4_472,
      bounce: 1_397,
      delivered: 4_982,
      dropped: 1_286,
      deferred: 296,
      spamreport: 336,
    },
  };
};

const collectionRateDemoCompareChart = () => {
  const pastData = [5, 15, 30, 37, 40, 45, 50, 50, 60, 70, 75, 85];
  const date = startOfYear(new Date());

  const past = pastData.map((value, index) => {
    const currentDate = addMonths(date, index);
    return {
      dateParts: {
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      },
      value,
    };
  });

  const currentData = [15, 25, 35, 40, 50, 55, 58, 60, 70, 80, 90, 100];

  const current = currentData.map((value, index) => {
    const currentDate = addMonths(date, index);
    return {
      dateParts: {
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      },
      value,
    };
  });

  return { past, current };
};

const demoPaymentsHistoryChart = () => {
  const data = [
    {
      onTime: { amount: 6_000_000, count: 520 },
      overdue: { amount: 800_000, count: 80 },
    },
    {
      onTime: { amount: 3_000_000, count: 280 },
      overdue: { amount: 1_100_000, count: 162 },
    },
    {
      onTime: { amount: 3_000_000, count: 287 },
      overdue: { amount: 900_000, count: 123 },
    },
    {
      onTime: { amount: 2_300_000, count: 350 },
      overdue: { amount: 0, count: 0 },
    },
  ];

  const date = new Date();

  return data.map((point, index) => {
    const currentDate = subMonths(date, index);

    return {
      dateParts: {
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      },
      ...point,
    };
  });
};

const demoTermsOfPaymentsChart = () => {
  return {
    net0: {
      numberOfInvoices: 45,
    },
    net45: {
      numberOfInvoices: 88,
    },
    net60: {
      numberOfInvoices: 221,
    },
    net30Eom: {
      numberOfInvoices: 88,
    },
  };
};

const getPastDemo = ({ value, percentage }) => {
  try {
    if (!isEqual(percentage, 0)) {
      return Math.round(value / (1 + percentage / 100));
    }

    return 0;
  } catch ({ message }) {
    console.error(message);
  }
};

const buildBuckets = ({ bucketsPast, bucketsCurrent }) => {
  const buckets = {};

  bucketsPast
    .sort((a, b) => a.dayFrom - b.dayFrom)
    .forEach(({ dayFrom, dayTo, value, type }) => {
      const currentValue = bucketsCurrent.find(
        ({ dayFrom: dayFromCurrent, dayTo: dayToCurrent }) =>
          isEqual(dayFrom, dayFromCurrent) && isEqual(dayTo, dayToCurrent),
      );

      const bucketName = isEqual(type, UPCOMING)
        ? i18n.t('dashboard.charts.totalAR.upcoming')
        : `${dayFrom}${isNil(dayTo) ? '+' : `-${dayTo}`}`;

      if (!isNil(currentValue) && !buckets.hasOwnProperty(bucketName)) {
        buckets[bucketName] = { type, data: [value, currentValue.value] };
      }
    });

  return buckets;
};

const buildDemoBuckets = (bucketsPast) => {
  const buckets = {};

  const upcomingBuckets = sortBy(
    bucketsPast.filter(({ type }) => isEqual(type, UPCOMING)),
    DAY_FROM,
  );
  const overdueBuckets = sortBy(
    bucketsPast.filter(({ type }) => isEqual(type, OVERDUE)),
    DAY_FROM,
  );

  for (const { localBalance } of upcomingBuckets) {
    const bucketName = i18n.t('dashboard.charts.totalAR.upcoming');
    const pastValue = getPastDemo({
      value: localBalance,
      percentage: demoPercentage.agingBuckets[0],
    });

    buckets[bucketName] = { type: UPCOMING, data: [pastValue, localBalance] };
  }

  for (const [index, { dayFrom, dayTo, localBalance }] of overdueBuckets.entries()) {
    const bucketName = `${dayFrom}${isNil(dayTo) ? '+' : `-${dayTo}`}`;
    const pastValue = getPastDemo({
      value: localBalance,
      percentage: demoPercentage.agingBuckets[index + 1],
    });

    buckets[bucketName] = { type: OVERDUE, data: [pastValue, localBalance] };
  }

  return buckets;
};

const fetchPastMonthlyPaymentForecast = async (payload) => {
  const { companyId, periods, users, search } = payload;

  const [previousMonth, currentMonth] = await Promise.all(
    periods.map((period) =>
      invoiceService.fetchCountClosed({
        companyId,
        period,
        users,
        search,
      }),
    ),
  );

  const dateParts = periods.map((period) => {
    const date = new Date(addDays(period[0], 2));

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    };
  });

  return [
    {
      sum: !isNil(previousMonth) && !isEmpty(previousMonth) ? previousMonth.closedInvoicesTotalAmount : 0,
      dateParts: dateParts[0],
    },
    {
      sum: !isNil(currentMonth) && !isEmpty(currentMonth) ? currentMonth.closedInvoicesTotalAmount : 0,
      dateParts: dateParts[1],
    },
  ];
};

export default {
  fetchInvoicesChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const res = await invoiceService.fetchChart(payload);

      commit('SET_INVOICES_CHART', res);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchCollectionRateCompareChart: async ({ commit }, { companyId, periods }) => {
    try {
      commit('IS_CHART_LOADING', true);

      const [past, current] = await Promise.all(
        periods.map(async (period) => invoiceService.fetchCollectionRateCompareChart({ companyId, period })),
      );

      commit('SET_COLLECTION_RATE_COMPARE_CHART', { past, current });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchCollectionRateDemoCompareChart: async ({ commit }) => {
    try {
      commit('IS_CHART_LOADING', true);
      commit('SET_COLLECTION_RATE_COMPARE_CHART', collectionRateDemoCompareChart());
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchCustomersChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const res = await customerService.fetchCustomersChart(payload);

      commit('SET_CUSTOMERS_CHART', res);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchBankChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const res = await bankReconciliationService.fetchBankChart(payload);

      commit('SET_BANK_CHART', res);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchTransactionByWeekDaysChart: async ({ commit }, payload) => {
    try {
      const res = await bankReconciliationService.transactionByWeekDays(payload);

      commit('SET_BANK_TRANSACTIONS_BY_WEEK_DAYS_CHART', res);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchTransactionByMonthWeeksChart: async ({ commit }, payload) => {
    try {
      const res = await bankReconciliationService.transactionByMonthWeeks(payload);

      commit('SET_BANK_TRANSACTIONS_BY_MONTH_WEEKS_CHART', res);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchOutstandingInvoicesChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const [outstanding, overdue] = await Promise.all([
        invoiceService.fetchCountOutstanding(payload),
        invoiceService.fetchCountOverdue(payload),
      ]);

      commit('SET_OUTSTANDING_INVOICES', outstanding);
      commit('SET_OVERDUE_INVOICES', overdue);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchPaymentGatewayChart: async ({ commit, rootGetters }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const hasPaymentGateway =
        !isNil(rootGetters['settings/merchants']) && !isEmpty(rootGetters['settings/merchants']);

      const paymentGateway =
        isEqual(Vue.auth.user()?.selectedCompany?.type, 'demo') || !hasPaymentGateway
          ? demoPaymentGatewayChart()
          : await invoiceService.fetchPaymentGatewayChart(payload);

      commit('SET_PAYMENT_GATEWAY_CHART', paymentGateway);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchPaymentsHistoryChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const res = isEqual(Vue.auth.user()?.selectedCompany?.type, 'demo')
        ? demoPaymentsHistoryChart()
        : await invoiceService.fetchPaymentsHistoryChart(payload);

      if (!isEmpty(res)) {
        commit('SET_PAYMENTS_HISTORY_CHART', res);
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchCustomerRiskChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const customersRisk = await customerService.fetchCustomersRisk(payload);

      commit('SET_CUSTOMERS_RISK_CHART', customersRisk);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchOutstandingInvoicesAmountChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const [upcomingOverdueAging] = await invoiceService.fetchUpcomingOverdueAging(payload);

      commit('SET_UPCOMING_OVERDUE_AGING_INVOICES', upcomingOverdueAging);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  setCreditLimitUsageChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      await new Promise((resolve) => {
        commit('SET_CREDIT_LIMIT_USAGE_CHART', payload);

        setTimeout(() => {
          resolve();
        }, 100);
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchAgingBucketsChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const res = await invoiceService.fetchAging(payload);

      commit('SET_AGING_BUCKETS_CHART', res);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchTermsOfPaymentsChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const res = isEqual(Vue.auth.user()?.selectedCompany?.type, 'demo')
        ? demoTermsOfPaymentsChart()
        : await invoiceService.fetchTermsOfPayment(payload);

      if (!isEmpty(res)) {
        commit('SET_TERMS_OF_PAYMENTS_CHART', res);
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  getAgingBucketsChartFilter: ({ dataPointIndex, chart }) => chart.options.xaxis.categories[dataPointIndex],
  getAgingBucketsChartDueDateFilter({ index, chart }) {
    const { dayFrom, dayTo, type } = chart.dueDays[index];

    let days = [dayFrom, dayTo];

    if (isEqual(type, UPCOMING)) {
      if (isNil(dayFrom)) {
        days = days.reverse();
      }

      days = [isNil(days[0]) ? days[0] : -days[0], isNil(days[1]) ? days[1] : -days[1]];
    }

    if (isEqual(type, OVERDUE)) {
      days = days.reverse();
    }

    return [
      isNil(days[0]) ? 0 : subDays(startOfDayUtc, days[0]).getTime(),
      isNil(days[1])
        ? subDays(new Date(), DAYS_TO_SUBTRACT_FOR_UPCOMING).getTime()
        : subDays(endOfDayUTC, days[1]).getTime(),
    ];
  },
  getOutstandingInvoicesChartFilter: ({ dataPointIndex, chart }) => chart.options.labels[dataPointIndex],
  getPaymentHistoryChartFilter: ({ dataPointIndex, seriesIndex, chart }) =>
    `${getMonthYearLabel(chart.options.xaxis.categories[dataPointIndex])}, ${chart.series[seriesIndex].name}`,
  getPaymentForecastFilter: ({ dataPointIndex, seriesIndex, chart }) =>
    `${
      isEqual(chart.options.xaxis.categories[dataPointIndex], 'Future')
        ? 'Future'
        : getMonthYearLabel(chart.options.xaxis.categories[dataPointIndex])
    }, ${chart.series[seriesIndex].name}`,
  getTermsOfPaymentChartFilter: ({ dataPointIndex, chart }) =>
    `${getMonthYearLabel(subMonths(new Date(), 1))}, ${chart.options.labels[dataPointIndex]}`,
  getTermsOfPaymentFilterValue: ({ dataPointIndex, chart }) => chart.seriesData[dataPointIndex]?.termOfPayment,
  getOutstandingInvoicesChartDueDateFilter({ index }) {
    return isEqual(index, 1)
      ? [startOfDayUtc, subDays(new Date(), DAYS_TO_SUBTRACT_FOR_UPCOMING).getTime()]
      : [0, endOfDayUTC];
  },
  getPaymentHistoryInvoicesCloseDateFilter({ chart, index }) {
    const date = chart.options.xaxis.categories[index];

    return [startOfMonth(date).getTime(), endOfMonth(date).getTime()];
  },
  getPaymentForecastFilterValue({ chart, seriesIndex, dataPointIndex }) {
    const date = chart.options.xaxis.categories[dataPointIndex];
    const key = chart.series[seriesIndex].key;

    if (isEqual(key, 'paid')) {
      return { closedDate: [startOfMonth(date).getTime(), endOfMonth(date).getTime()] };
    }

    if (isEqual(date, 'Future')) {
      return { forecastingBucket: `${key}4` };
    }

    const dates = getPaymentForecastDates();
    const currentYear = new Date(date).getFullYear();
    const currentMonth = new Date(date).getMonth() + 1;

    const dateIndex = dates.findIndex(({ month, year }) => isEqual(currentYear, year) && isEqual(currentMonth, month));

    return { forecastingBucket: `${key}${dateIndex + 1}` };
  },
  getTermsOfPaymentCreateDateFilter() {
    const date = subMonths(new Date(), 1);

    return [startOfMonth(date).getTime(), endOfMonth(date).getTime()];
  },
  fetchDsoChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const res = await companyService.fetchMetricMeasurements(payload);

      commit('SET_DSO_CHART', res);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  getCustomersRiskChartFilterValue: ({ index }) => index,
  getCustomersRiskChartTrackingEvent: ({ index }) => {
    const risks = ['A', 'B', 'C', 'D', 'F'];

    return `clicked_widget_customer_risk:${risks[index]}`;
  },
  fetchTotalARCompareChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const { companyId, dateRange } = payload;

      const [totalARPast, totalARCurrent, totalOverduePast, totalOverdueCurrent] = await Promise.all([
        companyService.fetchMetric({
          companyId,
          timestamp: dateRange[0],
          name: 'totalAr',
        }),
        companyService.fetchMetric({
          companyId,
          timestamp: dateRange[1],
          name: 'totalAr',
        }),
        companyService.fetchMetric({
          companyId,
          timestamp: payload.dateRange[0],
          name: 'sumInvoicesOverdue',
        }),
        companyService.fetchMetric({
          companyId,
          timestamp: payload.dateRange[1],
          name: 'sumInvoicesOverdue',
        }),
      ]);

      commit('SET_TOTAL_AR_COMPARE_CHART', {
        totalAR: [
          !isEmpty(totalARPast) ? totalARPast[0].value : 0,
          !isEmpty(totalARCurrent) ? totalARCurrent[0].value : 0,
        ],
        totalOverdue: [
          !isEmpty(totalOverduePast) ? totalOverduePast[0].value : 0,
          !isEmpty(totalOverdueCurrent) ? totalOverdueCurrent[0].value : 0,
        ],
        categories: dateRange,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchTotalARCompareDemoChart: async ({ commit }, { companyId, dateRange }) => {
    const [
      totalARCurrent,
      {
        stats: { totalSum: totalOverdueCurrent },
      },
    ] = await Promise.all([
      invoiceService.fetchTotalLocalBalance({ companyId }),
      invoiceService.list({
        companyId,
        page: 1,
        perPage: 25,
        search: { dueDate: [0, endOfDayUTC] },
        sort: { localBalance: -1 },
      }),
    ]);

    const totalARPast = getPastDemo({
      value: totalARCurrent,
      percentage: demoPercentage.totalAR,
    });
    const totalOverduePast = getPastDemo({
      value: totalOverdueCurrent,
      percentage: demoPercentage.totalOverdue,
    });

    commit('SET_TOTAL_AR_COMPARE_CHART', {
      totalAR: [totalARPast, totalARCurrent],
      totalOverdue: [totalOverduePast, totalOverdueCurrent],
      categories: dateRange,
    });
  },
  fetchOverdueCustomersCompareChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const { companyId, dateRange } = payload;

      const [overdueCustomersPast, overdueCustomersCurrent] = await Promise.all(
        dateRange.map((timestamp) =>
          companyService.fetchMetric({
            companyId,
            timestamp,
            name: 'countCustomersOverdue',
          }),
        ),
      );

      commit('SET_CUSTOMERS_OVERDUE_COMPARE_CHART', {
        series: [
          !isEmpty(overdueCustomersPast) ? overdueCustomersPast[0].value : 0,
          !isEmpty(overdueCustomersCurrent) ? overdueCustomersCurrent[0].value : 0,
        ],
        categories: dateRange,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchOverdueCustomersCompareDemoChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const { companyId, dateRange } = payload;
      const overdueCustomersCurrent = await customerService.fetchCountOverdue({ companyId });
      const overdueCustomersPast = getPastDemo({
        value: overdueCustomersCurrent,
        percentage: demoPercentage.overdueCustomers,
      });

      commit('SET_CUSTOMERS_OVERDUE_COMPARE_CHART', {
        series: [overdueCustomersPast, overdueCustomersCurrent],
        categories: dateRange,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchPastPayment: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);
      commit('SET_PAST_PAYMENT', await fetchPastMonthlyPaymentForecast(payload));
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchPaymentForecasting: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const res = await invoiceService.fetchChartProjected(payload);

      commit('SET_FUTURE_PAYMENT', res);
      commit('SET_FORECASTING_IS_CLICKABLE', true);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchPastMonthlyPaymentCompareChart: async ({ commit }, payload) => {
    try {
      commit('SET_PAST_COMPARE_PAYMENT', await fetchPastMonthlyPaymentForecast(payload));
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchPastWeeklyPaymentCompareChart: async ({ commit }, payload) => {
    const { companyId, periods } = payload;

    try {
      const [twoWeeksAgo, previousWeek, currentWeek] = await Promise.all(
        periods.map((period) =>
          invoiceService.fetchCountClosed({
            companyId,
            period,
          }),
        ),
      );

      commit('SET_PAST_COMPARE_PAYMENT', [
        {
          sum: !isNil(twoWeeksAgo) && !isEmpty(twoWeeksAgo) ? twoWeeksAgo.closedInvoicesTotalAmount : 0,
          date: dateToUtc(periods[0][0]),
        },
        {
          sum: !isNil(previousWeek) && !isEmpty(previousWeek) ? previousWeek.closedInvoicesTotalAmount : 0,
          date: dateToUtc(periods[1][0]),
        },
        {
          sum: !isNil(currentWeek) && !isEmpty(currentWeek) ? currentWeek.closedInvoicesTotalAmount : 0,
          date: dateToUtc(periods[2][0]),
        },
      ]);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchPaymentForecastingCompareChart: async ({ commit }, payload) => {
    try {
      const res = await invoiceService.fetchChartProjected(payload);

      commit('SET_COMPARE_PAYMENT_TYPE', payload.type);
      commit('SET_FUTURE_COMPARE_PAYMENT', res);
      commit('SET_FORECASTING_IS_CLICKABLE', false);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchPaymentForecastingCompareDemoChart: async ({ commit }, payload) => {
    try {
      commit('SET_COMPARE_PAYMENT_TYPE', payload.type);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchOutstandingInvoicesCompareChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const { companyId, dateRange } = payload;

      const [outstandingInvoicesPast, outstandingInvoicesCurrent, overdueInvoicesPast, overdueInvoicesCurrent] =
        await Promise.all([
          companyService.fetchMetric({
            companyId,
            timestamp: dateRange[0],
            name: 'countOutstandingInvoices',
          }),
          companyService.fetchMetric({
            companyId,
            timestamp: dateRange[1],
            name: 'countOutstandingInvoices',
          }),
          companyService.fetchMetric({
            companyId,
            timestamp: payload.dateRange[0],
            name: 'countInvoicesOverdue',
          }),
          companyService.fetchMetric({
            companyId,
            timestamp: payload.dateRange[1],
            name: 'countInvoicesOverdue',
          }),
        ]);

      commit('SET_OUTSTANDING_INVOICES_COMPARE_CHART', {
        outstanding: [
          !isEmpty(outstandingInvoicesPast) ? outstandingInvoicesPast[0].value : 0,
          !isEmpty(outstandingInvoicesCurrent) ? outstandingInvoicesCurrent[0].value : 0,
        ],
        overdue: [
          !isEmpty(overdueInvoicesPast) ? overdueInvoicesPast[0].value : 0,
          !isEmpty(overdueInvoicesCurrent) ? overdueInvoicesCurrent[0].value : 0,
        ],
        categories: dateRange,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchOutstandingInvoicesCompareDemoChart: async ({ commit }, { companyId, dateRange }) => {
    try {
      commit('IS_CHART_LOADING', true);

      const [outstandingCurrent, overdueCurrent] = await Promise.all([
        invoiceService.fetchCountOutstanding({ companyId }),
        invoiceService.fetchCountOverdue({ companyId }),
      ]);

      const outstandingPast = getPastDemo({
        value: outstandingCurrent,
        percentage: demoPercentage.outstanding,
      });
      const overduePast = getPastDemo({
        value: overdueCurrent,
        percentage: demoPercentage.overdue,
      });

      commit('SET_OUTSTANDING_INVOICES_COMPARE_CHART', {
        outstanding: [outstandingPast, outstandingCurrent],
        overdue: [overduePast, overdueCurrent],
        categories: dateRange,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchAgingBucketsCompareChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const {
        companyId,
        dateRange: [past, current],
      } = payload;
      const [bucketsPast, bucketsCurrent] = await Promise.all([
        companyService.fetchMetric({
          companyId,
          dateRange: [past, endOfDay(ignoreTimezoneOffsetEndOfDay(past))],
          name: 'sumAging',
        }),
        companyService.fetchMetric({
          companyId,
          dateRange: [current, endOfDay(ignoreTimezoneOffsetEndOfDay(current))],
          name: 'sumAging',
        }),
      ]);
      const buckets = buildBuckets({ bucketsPast, bucketsCurrent });

      commit('SET_AGING_BUCKETS_COMPARE_CHART', {
        buckets,
        categories: [past, current],
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchAgingBucketsCompareDemoChart: async ({ commit }, payload) => {
    try {
      commit('IS_CHART_LOADING', true);

      const { companyId, dateRange } = payload;
      const bucketsPast = await invoiceService.fetchAging({
        companyId,
      });
      const buckets = buildDemoBuckets(bucketsPast);

      commit('SET_AGING_BUCKETS_COMPARE_CHART', {
        buckets,
        categories: dateRange,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  fetchDisputesChart: async ({ commit }) => {
    try {
      commit('IS_CHART_LOADING', true);

      const disputes = [
        {
          label: i18n.t('dashboard.billingError'),
          count: 10,
          volume: 17667,
        },
        {
          label: i18n.t('dashboard.sales'),
          count: 20,
          volume: 35333,
        },
        {
          label: i18n.t('dashboard.logistics'),
          count: 30,
          volume: 53000,
        },
        {
          label: i18n.t('dashboard.deliveryTimeline'),
          count: 40,
          volume: 70667,
        },
        {
          label: i18n.t('dashboard.product'),
          count: 50,
          volume: 88333,
        },
      ];

      commit('SET_DISPUTES_CHART', disputes);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  async fetchEmailStatusMetricsChart({ commit, dispatch }, payload = {}) {
    try {
      commit('IS_CHART_LOADING', true);

      const res = isEqual(Vue.auth.user()?.selectedCompany?.type, 'demo')
        ? demoEmailStatusMetricsChart()
        : await dispatch('activityLog/fetchEmailEventMetrics', payload, { root: true });

      commit('SET_EMAIL_STATUS_METRICS_CHART', res);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('IS_CHART_LOADING', false);
    }
  },
  getEmailStatusMetricsFilterValue({ dataPointIndex, chart }) {
    return chart.seriesData[dataPointIndex].status;
  },
};
