import { isEmpty, isEqual } from 'lodash-es';

import { customWidgetsService } from '@services';

export default {
  async fetchCustomWidgets({ commit, dispatch }) {
    try {
      const widgets = await customWidgetsService.list({
        companyId: this._vm.$auth.user().selectedCompany.id,
      });

      if (!isEmpty(widgets)) {
        for (const widget of widgets) {
          if (widget.id) {
            dispatch('widgets/addLoadingWidgetState', widget.id, { root: true });
            dispatch('widgets/addFilterWidgetState', widget.id, { root: true });
          }
        }
      }

      commit('SET_CUSTOM_WIDGETS', widgets);
    } catch (error) {
      console.error(error);
    }
  },
  async create({ dispatch }, { widgetConfig, users, dashboardFilter }) {
    const id = await customWidgetsService.create({
      companyId: this._vm.$auth.user().selectedCompany.id,
      widgetConfig,
    });

    await dispatch('fetchCustomWidgets');

    dispatch('dashboard/addWidgetToSelectedWidgets', id, { root: true });

    await dispatch('widgets/getCustomWidgetData', { id, users, dashboardFilter }, { root: true });

    dispatch('setIsNew', { id, isNew: true });
  },
  async update({ commit, dispatch }, { id, widgetConfig, users, dashboardFilter }) {
    await customWidgetsService.update({
      id,
      body: {
        companyId: this._vm.$auth.user().selectedCompany.id,
        widgetConfig,
      },
    });

    commit('UPDATE_CUSTOM_WIDGET', { id, ...widgetConfig });

    await dispatch('widgets/getCustomWidgetData', { id, users, dashboardFilter }, { root: true });
  },
  async delete({ commit, dispatch }, id) {
    await customWidgetsService.delete({
      id,
      companyId: this._vm.$auth.user().selectedCompany.id,
    });

    commit('DELETE_CUSTOM_WIDGET', id);

    dispatch('dashboard/removeWidgetToSelectedWidgets', id, { root: true });
  },
  async getChartData({ commit }, payload) {
    const data = await customWidgetsService.getData({
      id: payload.id,
      body: {
        companyId: this._vm.$auth.user().selectedCompany.id,
        ...payload,
      },
    });

    if (payload.contentType) {
      const { data: rawData, contentType, filename } = data;
      const blob = new Blob([rawData], {
        type: contentType,
      });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      link.remove();

      window.URL.revokeObjectURL(link.href);
    } else {
      commit('SET_CHART_DATA', { id: payload.id, data });
    }
  },
  setIsNew({ commit }, { id, isNew = false }) {
    commit('SET_IS_NEW', { id, isNew });
  },
  getFilterAdditionalValue({ dataPointIndex, chart }) {
    const { filters, options } = chart;

    let customFieldFilter;

    if (isEqual(options.labels[dataPointIndex], 'other')) {
      customFieldFilter = {
        [`customFields.${filters.query.customField}`]: {
          operation: 'in',
          value: options.otherLabels,
        },
      };
    } else {
      customFieldFilter = {
        [`customFields.${filters.query.customField}`]: {
          operation: 'isEqual',
          value: options.labels[dataPointIndex],
        },
      };
    }

    return {
      customFieldFilter,
      filterField: filters.query.filterField,
      excludeEmpty: filters.query.excludeEmpty,
      search: filters.search,
    };
  },
};
