export class CustomWidget {
  constructor(objInit) {
    this.id = objInit.id;
    this.name = objInit.title;
    this.titleColor = undefined;
    this.titleAmount = undefined;
    this.trend = undefined;
    this.subtitle = undefined;
    this.tooltip = objInit.description;
    this.chart = {};
    this.toggleModal = false;
    this.withSearch = false;
    this.withTopDateSelector = true;
    this.isTitleCardClickable = false;
    this.query = undefined;
    this.path = undefined;
    this.showExport = true;
    this.disabled = false;
    this.titleDate = undefined;
    this.visible = true;
    this.isFeatureDisabled = false;
    this.isChartClickDisabled = false;
    this.isLocked = objInit.isLocked;
    this.dataSource = {
      [objInit.dataSource]: {
        customField: objInit.customField,
        filterField: objInit.filterField ? objInit.filterField?.split('.')[1] : undefined,
        isFilterFieldCustomField: objInit.filterField?.includes('customField'),
        search: objInit.search,
      },
    };
    this.isNew = false;
  }
}
