import { isEqual } from 'lodash-es';

export default {
  SET_CUSTOM_WIDGETS(state, customWidgets) {
    for (const widget of customWidgets) {
      if (!state.chartData.hasOwnProperty(widget.id)) {
        this._vm.$set(state.chartData, widget.id, {});
      }
    }

    state.customWidgets = customWidgets;
  },
  CREATE_CUSTOM_WIDGET(state, payload) {
    state.customWidgets.push(payload);
  },
  DELETE_CUSTOM_WIDGET(state, id) {
    state.customWidgets = state.customWidgets.filter((item) => !isEqual(item.id, id));
  },
  UPDATE_CUSTOM_WIDGET: (state, payload) => {
    const index = state.customWidgets.findIndex((item) => isEqual(item.id, payload.id));

    state.customWidgets[index] = payload;
  },
  SET_CHART_DATA(state, { id, data }) {
    state.chartData[id] = data;
  },
  SET_IS_NEW(state, { id, isNew }) {
    const index = state.customWidgets.findIndex((item) => isEqual(item.id, id));

    state.customWidgets[index].isNew = isNew;
  },
  RESET_IS_NEW(state) {
    state.customWidgets.forEach((item) => {
      if (item.hasOwnProperty('isNew')) {
        item.isNew = false;
      }
    });
  },
};
