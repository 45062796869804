import { COMPANY, ADAPTERS, BUSINESS_MODEL } from '@enums';
import { i18n } from '@plugins';

const { TYPES } = COMPANY;
const { TYPES: ADAPTER_TYPES } = ADAPTERS;

export default {
  isLoading: false,
  reloadPage: false,
  modal: {
    open: false,
    title: undefined,
    contentType: undefined,
  },
  companyTypes: Object.values(TYPES),
  companyType: undefined,
  companyDeleted: false,
  companyDeletionData: {
    deletedAt: null,
    byUserId: '',
    reason: '',
  },
  companyDeactivated: false,
  companySettingsState: null,
  companyNotificationTypes: [],
  companyWorkflowTypes: [],
  companyWorkflowTypesConfig: {},
  companyPremiumFeatureTypes: {},
  companyUCRTypes: {},
  companyCustomFieldTypes: {},
  companyAdapters: [],
  companyAdapterTypes: Object.values(ADAPTER_TYPES),
  adapterEmails: [],
  goLiveTimestamp: undefined,
  businessType: undefined,
  businessTypes: [
    {
      text: i18n.t('settings.backOffice.businessTypes.b2b'),
      value: 'b2b',
    },
    {
      text: i18n.t('settings.backOffice.businessTypes.b2c'),
      value: 'b2c',
    },
  ],
  companyAPASettings: {},
  billingIds: [],
  gavitiKeys: [],
};
