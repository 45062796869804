import { subWeeks } from 'date-fns';

function randomDate() {
  const start = subWeeks(new Date(), 1);
  const end = new Date();

  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

export const demoApaUsers = [
  {
    name: 'John Doe',
    email: 'john.doe@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Jane Smith',
    email: 'jane.smith@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Alice Johnson',
    email: 'alice.johnson@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Bob Brown',
    email: 'bob.brown@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Charlie Davis',
    email: 'charlie.davis@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Dana Lee',
    email: 'dana.lee@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Evan Harris',
    email: 'evan.harris@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Fiona Clark',
    email: 'fiona.clark@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'George Martinez',
    email: 'george.martinez@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Hannah Rodriguez',
    email: 'hannah.rodriguez@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Ian Walker',
    email: 'ian.walker@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Julia Hall',
    email: 'julia.hall@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Kevin Young',
    email: 'kevin.young@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Laura King',
    email: 'laura.king@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Michael Wright',
    email: 'michael.wright@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Natalie Scott',
    email: 'natalie.scott@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Oliver Green',
    email: 'oliver.green@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Paula Adams',
    email: 'paula.adams@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Quincy Baker',
    email: 'quincy.baker@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Rachel Carter',
    email: 'rachel.carter@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Steven Perez',
    email: 'steven.perez@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Tina Bell',
    email: 'tina.bell@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Ursula Rivera',
    email: 'ursula.rivera@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
  {
    name: 'Victor Roberts',
    email: 'victor.roberts@example.com',
    verified: {
      status: false,
    },
    lastLogin: null,
  },
  {
    name: 'Wendy Collins',
    email: 'wendy.collins@example.com',
    verified: {
      status: true,
    },
    lastLogin: randomDate(),
  },
];
