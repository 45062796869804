import { isNil, isEqual, isEmpty, isBoolean } from 'lodash-es';

const sortObjectByKeys = (el) => {
  return Object.keys(el)
    .sort()
    .reduce((obj, key) => {
      obj[key] = el[key];
      return obj;
    }, {});
};

export default {
  SET_IS_LOADING: (state) => {
    state.isLoading = !state.isLoading;
  },
  SET_RELOAD_PAGE: (state, payload) => {
    state.reloadPage = payload;
  },
  SET_MODAL: (state, payload) => {
    state.modal = payload;
  },
  SET_COMPANY_TYPE: (state, payload) => {
    state.companyType = payload;
  },
  UPDATE_COMPANY_TYPE: (state, payload) => {
    state.companyType = payload;
  },
  SET_COMPANY_NOTIFICATION_TYPES: (state, payload) => {
    state.companyNotificationTypes = payload;
  },
  TOGGLE_COMPANY_NOTIFICATION_TYPE: (state, { name, value }) => {
    state.companyNotificationTypes[name] = value;
  },
  SET_COMPANY_WORKFLOW_TYPES: (state, payload) => {
    state.companyWorkflowTypes = payload;
  },
  TOGGLE_COMPANY_WORKFLOW_TYPE: (state, { name, value }) => {
    state.companyWorkflowTypes[name] = value;
  },
  SET_COMPANY_WORKFLOW_TYPES_CONFIG: (state, { name, value }) => {
    state.companyWorkflowTypesConfig[name] = value;
  },
  UPDATE_COMPANY_WORKFLOW_TYPE_CONFIG: (state, { name, value }) => {
    state.companyWorkflowTypesConfig[name] = {
      ...state.companyWorkflowTypesConfig[name],
      ...value,
    };
  },
  SET_COMPANY_PREMIUM_FEATURE_TYPES: (state, payload) => {
    state.companyPremiumFeatureTypes = payload;
  },
  UPDATE_COMPANY_PREMIUM_FEATURE_TYPE: (state, { name, value }) => {
    state.companyPremiumFeatureTypes[name] = value;
  },
  SET_COMPANY_UCR_TYPES: (state, userCustomerRelations) => {
    state.companyUCRTypes = sortObjectByKeys(userCustomerRelations);
  },
  SET_COMPANY_APA_SETTINGS: (state, payload) => {
    state.companyAPASettings = payload;
  },
  TOGGLE_COMPANY_APA_SETTINGS: (state, { name, value }) => {
    state.companyAPASettings[name] = value;
  },
  SET_COMPANY_CUSTOM_FIELD_TYPES: (state, payload) => {
    const companyCustomFieldTypes = {};
    Object.keys(payload).forEach((type) => {
      companyCustomFieldTypes[type] = sortObjectByKeys(payload[type]);
    });
    state.companyCustomFieldTypes = companyCustomFieldTypes;
  },
  SET_COMPANY_ADAPTERS: (state, payload) => {
    state.companyAdapters = payload;
  },
  TOGGLE_COMPANY_ADAPTER_TYPE: (state, payload) => {
    const updatedAdapter = {
      ...payload,
      active: !payload.active,
    };

    state.companyAdapters = [
      ...state.companyAdapters.map((adapter) =>
        !isEqual(adapter.name, updatedAdapter.name) ? adapter : { ...adapter, ...updatedAdapter },
      ),
    ];
  },
  UPDATE_COMPANY_ADAPTER_TYPE_CONFIG: (state, payload) => {
    const adapterIndex = state.companyAdapters.findIndex(({ name }) => isEqual(name, payload.name));

    if (!isEqual(adapterIndex, -1)) {
      state.companyAdapters[adapterIndex] = payload;
    } else {
      state.companyAdapters.push(payload);
    }
  },
  SET_ADAPTER_EMAILS: (state, payload) => {
    state.adapterEmails = payload;
  },
  SET_BILLING_IDS: (state, payload) => {
    state.billingIds = payload;
  },
  UPDATE_ADAPTER_EMAIL: (state, payload) => {
    const adapterEmailIndex = state.adapterEmails.findIndex(({ _id }) => isEqual(_id, payload._id));

    state.adapterEmails[adapterEmailIndex].adapter = payload.adapter;
  },
  SET_GO_LIVE_TIMESTAMP: (state, payload) => {
    state.goLiveTimestamp = payload;
  },
  SET_BUSINESS_TYPE: (state, payload) => {
    state.businessType = payload;
  },
  SET_COMPANY_DELETED: (state, payload) => {
    state.companyDeleted = payload;
  },
  SET_COMPANY_DEACTIVATED: (state, payload) => {
    state.companyDeactivated = payload;
  },
  SET_COMPANY_SETTINGS_STATE: (state, payload) => {
    state.companySettingsState = payload;
  },
  SET_COMPANY_DELETION_DATA: ({ companyDeletionData }, { deletedAt, byUserId, reason }) => {
    companyDeletionData.deletedAt = deletedAt;
    companyDeletionData.byUserId = byUserId;
    companyDeletionData.reason = reason;
  },
  DELETE_COMPANY_ADAPTER: ({ companyAdapters }, { name, companyId }) => {
    const adapterIndex = companyAdapters.findIndex(
      ({ name: adapterName, companyId: adapterCompanyId }) =>
        isEqual(adapterName, name) && isEqual(adapterCompanyId, companyId),
    );

    companyAdapters.splice(adapterIndex, 1);
  },
  SET_GAVITI_KEYS: (state, payload) => {
    state.gavitiKeys = payload;
  },
};
