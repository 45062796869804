import { EmailEvent } from './emailEvent.dto';

export class EmailStatusMetric {
  constructor({ totalCustomers = 0, emailsSent = 0, totalEvents = 0, events = {} } = {}) {
    this.totalCustomers = totalCustomers || 0;
    this.emailsSent = emailsSent || 0;
    this.totalEvents = totalEvents || 0;
    this.events = new EmailEvent(events);
  }
}
