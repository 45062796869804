import Vue from 'vue';

import { httpClient } from '@api';

import { Insight } from './insights.dto';

export default {
  async list() {
    try {
      const payload = {
        companyId: Vue.auth.user()?.selectedCompany?.id,
        userId: Vue.auth.user().id,
      };

      const { list } = await httpClient.post('insights', payload);
      const normalizedList = list.map((item) => new Insight(item));

      return normalizedList;
    } catch (err) {
      console.warn(err);
    }
  },
  async count() {
    try {
      const payload = {
        companyId: Vue.auth.user()?.selectedCompany?.id,
        userId: Vue.auth.user().id,
      };

      const count = await httpClient.post('insights/count', payload);

      return count;
    } catch (err) {
      console.warn(err);
    }
  },
  async update(insightIds) {
    try {
      const payload = {
        insightIds,
        companyId: Vue.auth.user()?.selectedCompany?.id,
        userId: Vue.auth.user().id,
      };

      const { modifiedCount } = await httpClient.post('insights/update', payload);

      return modifiedCount;
    } catch (err) {
      console.warn(err);
      return false;
    }
  },
};
