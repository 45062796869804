import { createI18n } from 'vue-i18n-composable';
import VueI18n from 'vue-i18n';
import Vue from 'vue';

Vue.use(VueI18n);

const loadLocaleMessages = () => {
  const locales = import.meta.glob('@locales/**/*.json', {
    eager: true,
  });

  const messages = {};

  Object.keys(locales).forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    const path = key.replace(/^\.\/locales\//, './');

    if (matched && matched.length > 1) {
      const locale = matched[1];

      messages[locale] = locales[path];
    }
  });

  return messages;
};

export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
});
