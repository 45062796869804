export default {
  companiesMetricData: {
    totalLocalBalance: {},
    outstanding: {},
    overdue: {},
    aging: {},
    customerOverdue: {},
  },
  unifiedMetricData: {
    totalLocalBalance: null,
    outstanding: null,
    overdue: null,
    aging: {},
    customerOverdue: null,
  },
};
