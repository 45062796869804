import { tagsService } from '@services';

export default {
  fetchTags: async ({ commit }, { companyId, type }) => {
    try {
      const list = await tagsService.list({ companyId, type });

      commit('SET_TAGS', { type, list });
    } catch ({ message }) {
      console.error(message);
    }
  },
  deleteTag: async ({ dispatch }, payload) => {
    try {
      await tagsService.delete(payload);

      dispatch('fetchTags', payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateTag: async ({ dispatch }, payload) => {
    try {
      await tagsService.update(payload);

      dispatch('fetchTags', payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
};
