import { httpClient } from '@api';

export default {
  async list(payload) {
    try {
      return await httpClient.get('dashboard', payload);
    } catch (err) {
      console.warn(err);
    }
  },
  async create(payload) {
    try {
      return await httpClient.post('dashboard', payload);
    } catch (err) {
      console.warn(err);
    }
  },
  async update({ dashboardId, payload }) {
    try {
      return await httpClient.put(`dashboard/${dashboardId}`, payload);
    } catch (err) {
      console.warn(err);
    }
  },
  async delete({ dashboardId, companyId }) {
    try {
      const res = httpClient.delete(`dashboard/${dashboardId}`, {
        params: {
          companyId,
        },
      });
      return res;
    } catch (err) {
      console.warn(err);
    }
  },
};
