import { accessControlService } from '@services';

export default {
  fetchCompanyRoles: async ({ commit }, payload) => {
    try {
      const data = await accessControlService.fetchCompanyRoles(payload);

      commit('FETCH_COMPANY_ROLES', data);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchPermissions: async ({ commit }, payload) => {
    try {
      const data = await accessControlService.fetchPermissions(payload);

      commit('FETCH_PERMISSIONS', data);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchCompanyPermissions: async ({ commit }, payload) => {
    try {
      const data = await accessControlService.fetchCompanyPermissions(payload);

      commit('FETCH_COMPANY_PERMISSIONS', data);
    } catch ({ message }) {
      console.error(message);
    }
  },
  createCompanyRole: async ({ dispatch }, payload) => {
    try {
      await accessControlService.createCompanyRole(payload);
    } catch ({ message }) {
      console.error(message);
    } finally {
      await Promise.all([
        dispatch('fetchCompanyRoles', { companyId: payload.companyId }),
        dispatch('settings/loadCompanyUsers', { companyId: payload.companyId }, { root: true }),
      ]);
    }
  },
  updateCompanyRole: async ({ dispatch }, payload) => {
    try {
      await accessControlService.updateCompanyRole(payload);
    } catch ({ message }) {
      console.error(message);
    } finally {
      await dispatch('fetchCompanyRoles', { companyId: payload.companyId });
    }
  },
  deleteCompanyRole: async ({ dispatch }, payload) => {
    try {
      await accessControlService.deleteCompanyRole(payload);
    } catch ({ message }) {
      console.error(message);
    } finally {
      await Promise.all([
        dispatch('fetchCompanyRoles', { companyId: payload.companyId }),
        dispatch('settings/loadCompanyUsers', { companyId: payload.companyId }, { root: true }),
      ]);
    }
  },
};
