import Vue from 'vue';

export default {
  LOADING_STATUS(state, status) {
    if (status === true) {
      state.loadingStatus += 1;
    } else if (status === false) {
      state.loadingStatus -= 1;
    }
  },
  FETCH_COMPANY_SETTINGS(state, payload) {
    state.companySettings = payload;
  },
  UPDATE_COMPANY_SETTING(state, { name, value }) {
    Vue.set(state.companySettings, name, value);
  },
  LOAD_COMPANY_USERS(state, payload) {
    state.companyUsers = payload;
  },
  LOAD_COMPANY_APA_USERS(state, payload) {
    state.companyApaUsers = payload;
  },
  UPDATE_COMPANY_USERS(state, payload) {
    const index = state.companyUsers.findIndex((v) => v.id === payload.id);

    if (index > -1) {
      Object.assign(state.companyUsers[index], payload);
    }
  },
  ADD_COMPANY_USERS(state, payload) {
    state.companyUsers.push(payload);
  },
  LOAD_SETTINGS(state, payload) {
    state.settings = payload;
  },
  UPDATE_SETTINGS(state, payload) {
    state.settings = payload;
  },
  UPDATE_COMPANY_DATA(state, payload) {
    state.companyData = payload;
  },
  SHOW_LOGS(state) {
    state.showLogs = true;
  },
  HIDE_LOGS(state) {
    state.showLogs = false;
  },
  SET_MERCHANTS(state, payload) {
    state.merchants = payload;
  },
  SET_WORKFLOW_CONFIGURATIONS_CHECK(state, payload) {
    state.workflowConfigurationsCheck = payload;
  },
  SET_MODAL: (state, payload) => {
    state.modal = payload;
  },
  SET_COMPANY_AUDIT_LIST: (state, payload) => {
    state.companyAuditList = payload;
  },
  SET_COMPANY_AUDIT_STATS: (state, payload) => {
    state.companyAuditStats = payload;
  },
  SET_IS_AUDIT_EXPORT_LOADING: (state, payload) => {
    state.isAuditExportLoading = payload;
  },
};
