import { DEVICE } from '@enums';

export default {
  lastDataUpdate: [],
  isDeviceMobile: false,
  deviceOrientation: DEVICE.ORIENTATION.PORTRAIT,
  sidebar: false,
  menuButton: true,
  appNotifications: [],
  appNotificationsCount: 0,
  currentPage: 1,
  pagesCount: 0,
  showModal: {},
  showAppDrawer: false,
  appDrawerMode: null,
  appDrawerTitle: null,
  customers: [],
  invoices: [],
  isAppNotificationsLoading: false,
  demoAppNotifications: [],
  accessControl: undefined,
  darkMode: false,
  showConfetti: undefined,
  isAppLoading: true,
};
