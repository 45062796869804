export default {
  loadingWidgets: {
    totalAR: false,
    agingBuckets: false,
    dso: false,
    openInvoices: false,
    pastDueCustomers: false,
    payments: false,
    collectionRate: false,
  },
};
