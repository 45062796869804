import { isEmpty, isNil } from 'lodash-es';
import { filterService } from '@services';

export default {
  fetchTopFilters: async ({ commit }, payload) => {
    try {
      const list = await filterService.list(payload);

      commit('SET_TOP_FILTERS', { list, type: payload.type });
    } catch ({ message }) {
      console.error(message);
    }
  },
  newTopFilter: async (_, payload) => {
    try {
      await filterService.create(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateTopFilter: async (_, payload) => {
    try {
      await filterService.update(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  deleteTopFilter: async (_, payload) => {
    try {
      await filterService.delete(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  async getSavedFiltersTitlesByTag({ dispatch, getters }, { companyId, filterType, tag }) {
    const { topFilters } = getters;

    let filters = { ...topFilters };
    if (isEmpty(filters)) {
      await dispatch('fetchTopFilters', {
        companyId,
        type: filterType,
      });
    }

    filters = await dispatch('getTopFiltersByType', { filters, filterType });

    return filters
      .filter((savedFilter) => savedFilter?.filter?.advancedFiltersOptions?.tags?.includes(tag))
      .map(({ title }) => title);
  },
  getTopFiltersByType(_, { filters, filterType }) {
    return !isNil(filters[`${filterType}s`]) ? JSON.parse(JSON.stringify(filters[`${filterType}s`])) : [];
  },
};
