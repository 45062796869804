export class EmailEvent {
  constructor({ open = 0, bounce = 0, delivered = 0, dropped = 0, deferred = 0, spamreport = 0 } = {}) {
    this.open = open;
    this.bounce = bounce;
    this.delivered = delivered;
    this.dropped = dropped;
    this.deferred = deferred;
    this.spamreport = spamreport;
  }
}
