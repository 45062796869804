import Vue from 'vue';

import { isEmpty, sortBy } from 'lodash-es';
import { httpClient } from '@api';

import { BankReconciliation } from './bank-reconciliation.dto';
import { BankAccount } from './bank-account.dto';
import { BankChart } from './bank-chart.dto.js';

export default {
  list: async (params) => {
    try {
      let normalizedList = [];

      const response = await httpClient.post('bank-reconciliation/transaction/list', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        ...params,
      });

      if (params?.contentType === 'xlsx' || params?.contentType === 'csv') return response;

      const { data, total, accountIds = [] } = response;

      normalizedList = isEmpty(data) ? [] : data.map((item) => new BankReconciliation(item));

      return { data: normalizedList, total, accountIds };
    } catch (error) {
      console.error(error);
    }
  },
  update: async (params) => {
    try {
      await httpClient.post('bank-reconciliation/transaction/update', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        ...params,
      });
    } catch (error) {
      console.error(error);
    }
  },
  accountList: async (params) => {
    try {
      let normalizedList = [];

      const { data, total, isReconnectNeeded } = await httpClient.post('bank-reconciliation/account/list', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        ...params,
      });

      if (!isEmpty(data)) {
        normalizedList = data.map((item) => new BankAccount(item));
      }

      return { data: normalizedList, total, isReconnectNeeded };
    } catch (error) {
      console.warn(error);
    }
  },
  fetchBankChart: async (params) => {
    try {
      let normalizedList = [];

      const data = await httpClient.post('bank-reconciliation/transaction/chart', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        ...params,
      });

      if (!isEmpty(data)) {
        normalizedList = sortBy(
          data.map((item) => new BankChart(item)),
          ['dateParts.year', 'dateParts.month'],
        );
      }

      return normalizedList;
    } catch (error) {
      console.error(error);
    }
  },
  transactionByWeekDays: async (params) => {
    try {
      const data = await httpClient.post('bank-reconciliation/transaction/chart/by-week-days', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
        ...params,
      });

      return sortBy(data, 'dayOfWeek');
    } catch (error) {
      console.error(error);
    }
  },
  transactionByMonthWeeks: async (params) => {
    try {
      const data = await httpClient.post('bank-reconciliation/transaction/chart/by-month-weeks', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
        ...params,
      });

      return data;
    } catch (error) {
      console.error(error);
    }
  },
  generateDemo: async (params) => {
    return httpClient.post('bank-reconciliation/demo/generate', {
      companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
      ...params,
    });
  },
  findOne: async (params) => {
    try {
      const item = await httpClient.post('bank-reconciliation/transaction/find-one', {
        companyId: Vue.prototype.$auth.user()?.selectedCompany?.id,
        ...params,
      });

      return new BankReconciliation(item);
    } catch (error) {
      console.error(error);
    }
  },
};
