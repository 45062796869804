import { isEqual, isNil, isString, cloneDeep } from 'lodash-es';

import { i18n } from '@plugins';

const canPlaceWidget = (layout, x, y, w, h) => {
  for (const widget of layout) {
    if (x < widget.x + widget.w && x + w > widget.x && y < widget.y + widget.h && y + h > widget.y) {
      return false;
    }
  }

  return true;
};

const findNextColumn = (layout, currentX) => {
  const overlapWidgets = layout.filter((widget) => widget.x <= currentX && widget.x + widget.w > currentX);
  const maxOverlapX = Math.max(...overlapWidgets.map((widget) => widget.x + widget.w));

  return maxOverlapX;
};

export default {
  SET_DASHBOARDS: (state, payload) => {
    state.dashboards = payload;
  },
  SET_SELECTED_DASHBOARD: (state, { selectedDashboardId }) => {
    state.selectedDashboardId = selectedDashboardId;

    if (!isNil(state.dashboards)) {
      let selectedDashboard = state.dashboards.find(({ id }) => isEqual(id, selectedDashboardId));

      if (isNil(selectedDashboard)) {
        selectedDashboard = state.dashboards.find(({ locked }) => !isNil(locked) && locked);
        state.defaultDashboardId = selectedDashboard.id;
      }

      state.selectedLayout = cloneDeep(selectedDashboard.layout);

      const selectedWidgets = selectedDashboard.layout.map(({ i }) => i);

      state.selectedWidgets = selectedWidgets;
      state.selectedDashboardName = selectedDashboard.name;
    }
  },
  SET_DEFAULT_DASHBOARD_ID: (state, defaultDashboardId) => {
    state.defaultDashboardId = defaultDashboardId;
  },
  UPDATE_SELECTED_LAYOUT: (state, payload) => {
    const updatedLayout = cloneDeep(payload);

    state.selectedLayout = updatedLayout;
  },
  SET_DASHBOARD_EDIT_MODE: (state, dashboardEditMode) => {
    state.dashboardEditMode = dashboardEditMode;
  },
  DELETE_DASHBOARD: (state, dashboardId) => {
    const dashboardIndex = state.dashboards.findIndex(({ id }) => isEqual(id, dashboardId));

    state.dashboards[dashboardIndex].deleted = true;
  },
  DISCARD_NEW_DASHBOARD: (state) => {
    state.dashboards = state.dashboards.filter(({ id }) => !isEqual(id, 'new'));
  },
  ADD_WIDGET_TO_SELECTED_WIDGETS: (state, id) => {
    state.selectedWidgets.push(id);
  },
  SET_SELECTED_WIDGETS: (state, widgets = []) => {
    const layout = cloneDeep(state.selectedLayout);

    state.selectedWidgets = widgets;

    const widgetIds = layout.map(({ i }) => i);

    widgets.forEach((id) => {
      if (!widgetIds.includes(id)) {
        let defaultWidget = state.defaultDashboardLayout.find(({ i }) => isEqual(i, id));

        if (isNil(defaultWidget)) {
          defaultWidget = {
            i: id,
            w: 4,
            h: 8,
          };
        }

        let placed = false;
        let y = 0;

        while (!placed) {
          let x = 0;

          while (x + defaultWidget.w <= 12) {
            if (canPlaceWidget(layout, x, y, defaultWidget.w, defaultWidget.h)) {
              defaultWidget.x = x;
              defaultWidget.y = y;

              layout.push(defaultWidget);

              placed = true;

              break;
            }

            x = findNextColumn(layout, x);
          }

          y += 1;
        }
      }
    });

    state.selectedLayout = layout.filter((widget) => widgets.includes(widget.i));
  },
  SET_SELECTED_DASHBOARD_NAME: (state, selectedDashboardName) => {
    state.selectedDashboardName = isString(selectedDashboardName) ? selectedDashboardName.trim() : '';
  },
  UPDATE_WIDGET: (state, payload) => {
    const widgetIndex = state.selectedLayout.findIndex(({ i }) => isEqual(i, payload.i));

    state.selectedLayout[widgetIndex] = payload;
  },
  CREATE_DASHBOARD: (state, payload) => {
    state.dashboards.push(payload);
  },
  SET_CHART_CENTRAL_DASHBOARD: (state) => {
    state.selectedDashboardId = 'chart-central';
    state.selectedLayout = [];
    state.selectedDashboardName = i18n.t('nav.chartCentral');
  },
  ADD_SELECTED_DASHBOARD_TO_HISTORY: (state, selectedDashboardId) => {
    if (!isEqual(selectedDashboardId, 'new')) {
      const historyIndex = state.selectedDashboardHistory.findIndex((id) => isEqual(id, selectedDashboardId));

      if (historyIndex !== -1) {
        state.selectedDashboardHistory.splice(historyIndex, 1);
      }

      state.selectedDashboardHistory.push(selectedDashboardId);
    }
  },
};
