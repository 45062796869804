export default {
  dso: { value: 'N/A' },
  cei: 'N/A',
  dsoStandard: { value: 'N/A' },
  add: { value: 'N/A' },
  mdd: { value: 'N/A' },
  closedInvoicesTotalAmount: 0,
  workflowsActivities: undefined,
  notesActivities: undefined,
  manualNotesActivities: undefined,
  outstandingInvoices: 0,
  customersCreditLimit: 0,
  collectionRate: { value: 'N/A' },
  trends: {
    totalAr: undefined,
    countOutstandingInvoices: undefined,
    countCustomersOverdue: undefined,
    dso: undefined,
    dsoStandard: undefined,
    add: undefined,
    mdd: undefined,
    collectionRate: undefined,
    customersCreditLimit: undefined,
  },
  loadingWidgets: {
    totalAR: false,
    agingBuckets: false,
    dso: false,
    cei: false,
    dsoStandard: false,
    add: false,
    mdd: false,
    closedInvoices: false,
    workflowsActivities: false,
    notesActivities: false,
    manualNotesActivities: false,
    topDebtors: false,
    openInvoices: false,
    openInvoicesChart: false,
    openInvoicesAmountChart: false,
    customersRiskChart: false,
    pastDueCustomers: false,
    payments: false,
    customersChart: false,
    invoicesChart: false,
    collectionRate: false,
    termsOfPayments: false,
    paymentGatewayChart: false,
    paymentsHistoryChart: false,
    creditLimitUsage: false,
    creditReport: false,
    disputesChart: false,
    customersCreditLimit: false,
    emailStatusMetricsChart: false,
  },
  filterWidgets: {
    totalAR: false,
    agingBuckets: false,
    dso: false,
    cei: false,
    dsoStandard: false,
    add: false,
    mdd: false,
    closedInvoices: false,
    workflowsActivities: false,
    notesActivities: false,
    manualNotesActivities: false,
    topDebtors: false,
    openInvoices: false,
    openInvoicesChart: false,
    openInvoicesAmountChart: false,
    customersRiskChart: false,
    pastDueCustomers: false,
    payments: false,
    customersChart: false,
    invoicesChart: false,
    collectionRate: false,
    termsOfPayments: false,
    paymentGatewayChart: false,
    paymentsHistoryChart: false,
    creditLimitUsage: false,
    disputesChart: false,
    customersCreditLimit: false,
    emailStatusMetricsChart: false,
  },
  manualTimeSaved: {
    value: 0,
    startCountDate: 0,
  },
};
