const RATES = {
  interest: {
    value: 'ratePct',
    date: 'lastUpdated',
  },
  inflation: {
    value: 'yearlyRatePct',
    date: 'period',
  },
};

Object.freeze(RATES);

export default RATES;
