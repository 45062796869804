import Vue from 'vue';

import { isString, isEqual, isFunction } from 'lodash-es';
import Intercom from './lib';

let intercomInstalled = false;

export function install(VueInstance, options = {}) {
  if (!isEqual(Vue, VueInstance)) console.error(`Multiple instances of Vue detected.`);

  const { appId } = options;

  if (!isString(appId)) {
    console.warn("You didn't specify an Intercom appId.");

    return;
  }

  const intercom = new Intercom({ appId });

  VueInstance.mixin({
    async beforeCreate() {
      if (intercomInstalled) return;

      if (isFunction(window.Intercom)) {
        this.$intercom.init();
        this.$intercom.callIntercom('reattach_activator');
        this.$intercom.update();
      } else {
        const placeholder = (...args) => placeholder.c(args);

        placeholder.q = [];
        placeholder.c = (args) => placeholder.q.push(args);

        window.Intercom = placeholder;

        this.$intercom = VueInstance.observable(intercom);

        VueInstance.prototype.$intercom = this.$intercom;
      }

      intercomInstalled = true;
    },
  });
}
