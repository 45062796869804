import { httpClient } from '@api';

export default {
  upload: async (payload) => {
    return httpClient.post('custom-fields/attachment/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  rename: async (payload) => httpClient.post('custom-fields/attachment/rename', payload),
  delete: async (payload) => httpClient.post('custom-fields/attachment/delete', payload),
  link: async (payload) => httpClient.post('custom-fields/attachment/link', payload),
};
