const DICTIONARY = {
  methods: {
    event: 'event',
  },
  categories: {
    chart_widget: 'chart_widget',
    clear_payment: 'clear_payment',
    columns: 'columns',
    company_details: 'company_details',
    company_features: 'company_features',
    credit_report: 'credit_report',
    dashboard: 'dashboard',
    dashboard_actions: 'dashboard_actions',
    delete: 'delete',
    detail_edit: 'detail_edit',
    detail_view: 'detail_view',
    edit: 'edit',
    filters: 'filters',
    general_action: 'general_action',
    insights: 'insights',
    item_action: 'item_action',
    pagination_controls: 'pagination_controls',
    preview: 'preview',
    registration: 'registration',
    search: 'search',
    select: 'select',
    send_form_to_prospect: 'send_form_to_prospect',
    sign_in: 'sign_in',
    transaction_allocation: 'transaction_allocation',
    upload_files: 'upload_files',
    user_details: 'user_details',
    warning: 'warning',
    new_company: 'new_company',
    notifications: 'notifications',
    score_card: 'score_card',
    intro_email: 'intro_email',
  },
  actions: {
    applied: 'applied',
    cleared: 'cleared',
    clicked: 'clicked',
    closed: 'closed',
    collapsed: 'collapsed',
    deselected: 'deselected',
    dismissed: 'dismissed',
    edited: 'edited',
    expanded: 'expanded',
    filtered: 'filtered',
    response: 'response',
    selected: 'selected',
    shown: 'shown',
    sorted: 'sorted',
    submitted: 'submitted',
  },
  elements: {
    button: 'button',
    chart: 'chart',
    checkbox: 'checkbox',
    component: 'component',
    dropdown: 'dropdown',
    filter: 'filter',
    form_dropdown: 'form:dropdown',
    form: 'form',
    header_search: 'header_search',
    icon: 'icon',
    item: 'item',
    link: 'link',
    menu_icon: 'menu_icon',
    menu: 'menu',
    radio_buttons: 'radio_buttons',
    side_panel: 'side_panel',
    toolbar_extended_icon: 'toolbar_extended_icon',
    toolbar_icon: 'toolbar_icon',
    toolbar_menu: 'toolbar_menu',
    legend_button: 'legend_button',
  },
  types: {
    chart_widget: 'chart_widget',
    data: 'data',
    footer: 'footer',
    form: 'form',
    header: 'header',
    menu: 'menu',
    modal: 'modal',
    page: 'page',
    side_panel: 'side_panel',
    table: 'table',
    toast: 'toast',
    toolbar: 'toolbar',
    side_navbar: 'side_navbar',
    top_navbar: 'top_navbar',
    widget: 'widget',
    alert: 'alert',
  },
};

Object.freeze(DICTIONARY);

export default DICTIONARY;
