import { isEqual, isEmpty, isNil, cloneDeep, get, isString } from 'lodash-es';
import { mdiTrendingUp, mdiTrendingDown } from '@mdi/js';
import { formatCurrency } from '@utils/money';
import { i18n } from '@plugins';
import Vue from 'vue';
import { getDaysInMonth, addMonths, format } from 'date-fns';

import { BUSINESS_MODEL, RATES, COMPANY } from '@enums';
import defaultEuLayout from './default-eu-layout';

const { FREE } = BUSINESS_MODEL;
const { TYPES: COMPANY_TYPES } = COMPANY;

const RATE_WIDGETS = ['interest', 'inflation'];
const DEMO_WIDGETS = ['disputesChart'];

const removeDemoWidgets = (layout) => layout.filter((item) => !DEMO_WIDGETS.includes(item.i));

export default {
  dashboards: ({ dashboards }) => dashboards,
  selectedDashboardId: ({ selectedDashboardId }) => selectedDashboardId,
  selectedDashboardHistory: ({ selectedDashboardHistory }) => selectedDashboardHistory,
  selectedLayout: ({ selectedLayout, selectedDashboardId, gavitiDashboardId }) => {
    const isDefaultGavitiDashboard = isEqual(selectedDashboardId, gavitiDashboardId);
    const isDemoCompany = isEqual(Vue.auth.user()?.selectedCompany?.type, COMPANY_TYPES.DEMO);

    let euLayout = cloneDeep(defaultEuLayout);

    if (!isDemoCompany) {
      euLayout = removeDemoWidgets(euLayout);
      selectedLayout = removeDemoWidgets(selectedLayout);
    }

    if (isDefaultGavitiDashboard) {
      return euLayout;
    }

    return selectedLayout.filter((item) => !RATE_WIDGETS.includes(item.i));
  },
  gavitiDashboardId: ({ gavitiDashboardId }) => gavitiDashboardId,
  defaultDashboardId: ({ defaultDashboardId }) => defaultDashboardId,
  dashboardEditMode: ({ dashboardEditMode }) => dashboardEditMode,
  selectedDashboardName: ({ selectedDashboardName }) => selectedDashboardName,
  selectedWidgets: ({ selectedWidgets }) => selectedWidgets,
  defaultDashboardLayout: ({ defaultDashboardLayout }) => defaultDashboardLayout,
  users: ({}, _, rootGetters) => {
    const {
      settings: { companyUsers },
    } = rootGetters;

    const users = cloneDeep(companyUsers);

    return users
      .filter(
        (user) =>
          user.accessControlRoleIds.length > 1 ||
          !user.accessControlRoleIds.includes('4976a37a-471d-4467-ae46-eec7f104cd59'),
      )
      .map((user) => {
        if (!user.activeInCompany) {
          user.name = `${user.name} (${i18n.t('workflow.itemInactive')})`;
        }

        return user;
      });
  },
  widgets: (_state, _getters, _rootState, rootGetters) => {
    const {
      'charts/customersChart': customersChart,
      'charts/invoicesChart': invoicesChart,
      'charts/paymentsForecastChart': paymentsForecastChart,
      'charts/agingBucketsChart': agingBucketsChart,
      'charts/termsOfPaymentsChart': termsOfPaymentsChart,
      'charts/paymentGatewayChart': paymentGatewayChart,
      'charts/outstandingInvoicesChart': outstandingInvoicesChart,
      'charts/outstandingInvoicesAmountChart': outstandingInvoicesAmountChart,
      'charts/customersRiskChart': customersRiskChart,
      'charts/disputesChart': disputesChart,
      'charts/paymentsHistoryChart': paymentsHistoryChart,
      'charts/emailStatusMetricsChart': emailStatusMetricsChart,
      'customers/countOverdueCustomers': countOverdueCustomers,
      'customers/percentageOverdueCustomers': percentageOverdueCustomers,
      'invoices/totalARBalance': totalARBalance,
      'widgets/dso': dso,
      'widgets/cei': cei,
      'widgets/dsoStandard': dsoStandard,
      'widgets/add': add,
      'widgets/mdd': mdd,
      'widgets/dsoTrend': dsoTrend,
      'widgets/dsoStandardTrend': dsoStandardTrend,
      'widgets/addTrend': addTrend,
      'widgets/mddTrend': mddTrend,
      'widgets/collectionRateTrend': collectionRateTrend,
      'widgets/totalArTrend': totalArTrend,
      'widgets/customersCreditLimitTrend': customersCreditLimitTrend,
      'widgets/countOutstandingInvoicesTrend': countOutstandingInvoicesTrend,
      'widgets/countCustomersOverdueTrend': countCustomersOverdueTrend,
      'widgets/closedInvoicesTotalAmount': closedInvoicesTotalAmount,
      'widgets/outstandingInvoices': outstandingInvoices,
      'widgets/customersCreditLimit': customersCreditLimit,
      'settings/uiShowPaymentForecasting': uiShowPaymentForecasting,
      'settings/businessModel': businessModel,
      'settings/toggleCreditManagement': toggleCreditManagement,
      'settings/companyCurrency': currency,
      'rates/rates': rates,
      'widgets/collectionRate': collectionRate,
      'widgets/manualTimeSaved': manualTimeSavedValue,
      'settings/merchants': merchants,
      'customWidgets/customWidgets': customWidgets,
    } = rootGetters;

    const getOverdueCustomersQuery = () => {
      const now = new Date();

      const startOfPreviousDay = Date.UTC(now.getUTCFullYear(), now.getMonth(), now.getDate());

      return { dueDate: [0, startOfPreviousDay] };
    };

    const getTrendAppearance = ({ value, type }) => {
      const trend = { icon: value > 0 ? mdiTrendingUp : mdiTrendingDown };
      if (isEqual(Math.round(value), 0)) {
        return {};
      }

      switch (type) {
        case 'increaseGood':
          trend.color = value > 0 ? 'primary--text' : 'red--text text--lighten-2';

          break;
        case 'decreaseGood':
          trend.color = value < 0 ? 'primary--text' : 'red--text text--lighten-2';

          break;
        default:
          break;
      }

      return trend;
    };

    const getRateValue = (rate) => {
      const payload = {
        value: 0,
        date: undefined,
      };

      const data = get(rates, rate, []);

      if (isEmpty(data)) return payload;

      const values = data.find((item) => !isNil(item));

      if (isNil(values)) return payload;

      payload.value = get(values, RATES[rate]?.value, undefined);
      payload.date = get(values, RATES[rate]?.date, undefined);

      return payload;
    };

    const isDemoCompany = isEqual(Vue.auth.user()?.selectedCompany?.type, COMPANY_TYPES.DEMO);
    const totalArDso = dso?.totalAr;
    const totalSalesDso = dso?.totalSales;
    const totalArDsoStandard = dsoStandard?.totalAr;
    const totalSalesDsoStandard = dsoStandard?.totalSales;
    const addDso = add?.calculationValues?.dso || 0;
    const addDsoStandard = add?.calculationValues?.dsoStandard || 0;
    const mddClosedInvoicesCount = mdd?.calculationValues?.closedInvoicesCount || 0;
    const daysInMonth = getDaysInMonth(addMonths(new Date(), -1));
    const month = format(addMonths(new Date(), -1), 'MMMM');
    const collectionRateValue = collectionRate?.value;
    const collectedCollectionRate = collectionRate?.calculationValues?.collected;
    const expectedCollectedCollectionRate = collectionRate?.calculationValues?.expectedCollected;
    const hasPaymentGateway = !isNil(merchants) && !isEmpty(merchants);

    return {
      totalAR: {
        id: 'totalAR',
        name: i18n.t('dashboard.totalAR'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: {
          value: totalArTrend,
          appearance: getTrendAppearance({ value: totalArTrend, type: 'neutral' }),
        },
        subtitle: {
          type: 'currency',
          value: formatCurrency(totalARBalance, currency, false),
          color: 'primary--text',
        },
        tooltip: i18n.t('dashboard.help.totalAR'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      agingBuckets: {
        id: 'agingBuckets',
        name: i18n.t('dashboard.agingBuckets'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: undefined,
        chart: agingBucketsChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      dso: {
        id: 'dso',
        name: i18n.t('dashboard.dso'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: {
          value: dsoTrend,
          appearance: getTrendAppearance({ value: dsoTrend, type: 'decreaseGood' }),
        },
        subtitle: {
          type: 'numeric',
          value: get(dso, 'value', 0),
          color: 'inverse--text',
        },
        tooltip:
          totalArDso && totalSalesDso
            ? i18n.t('dashboard.help.dsoExample', {
                month,
                totalAr: formatCurrency(totalArDso, currency, false),
                totalSales: formatCurrency(totalSalesDso, currency, false),
                daysInMonth,
                days: dso.value,
              })
            : isDemoCompany
              ? i18n.t('dashboard.help.dsoExample', {
                  month,
                  totalAr: formatCurrency(16_647_610, currency, false),
                  totalSales: formatCurrency(7_702_625, currency, false),
                  daysInMonth: 31,
                  days: 67,
                })
              : i18n.t('dashboard.help.dso'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      cei: {
        id: 'cei',
        name: i18n.t('dashboard.cei'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: {
          type: 'bigPercentage',
          value: cei,
          color: 'inverse--text',
        },
        tooltip: i18n.t('dashboard.help.cei'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      dsoStandard: {
        id: 'dsoStandard',
        name: i18n.t('dashboard.dsoStandard'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: {
          value: dsoStandardTrend,
          appearance: getTrendAppearance({ value: dsoStandardTrend, type: 'decreaseGood' }),
        },
        subtitle: {
          type: 'numeric',
          value: get(dsoStandard, 'value', 0),
          color: 'inverse--text',
        },
        tooltip:
          totalArDsoStandard && totalSalesDsoStandard
            ? i18n.t('dashboard.help.dsoStandardExample', {
                month,
                totalAr: formatCurrency(totalArDsoStandard, currency, false),
                totalSales: formatCurrency(totalSalesDsoStandard, currency, false),
                daysInMonth,
                days: dsoStandard.value,
              })
            : isDemoCompany
              ? i18n.t('dashboard.help.dsoStandardExample', {
                  month,
                  totalAr: formatCurrency(22_113_988, currency, false),
                  totalSales: formatCurrency(7_702_625, currency, false),
                  daysInMonth: 31,
                  days: 89,
                })
              : i18n.t('dashboard.help.dsoStandard'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      add: {
        id: 'add',
        name: i18n.t('dashboard.add'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: {
          value: addTrend,
          appearance: getTrendAppearance({ value: addTrend, type: 'decreaseGood' }),
        },
        subtitle: {
          type: 'numeric',
          value: add.value,
          color: 'inverse--text',
        },
        tooltip:
          addDso && addDsoStandard
            ? i18n.t('dashboard.help.addExample', {
                month,
                dso: addDso,
                dsoStandard: addDsoStandard,
                days: add.value,
              })
            : isDemoCompany
              ? i18n.t('dashboard.help.addExample', {
                  month,
                  dso: 67,
                  dsoStandard: 89,
                  days: 22,
                })
              : i18n.t('dashboard.help.add'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      mdd: {
        id: 'mdd',
        name: i18n.t('dashboard.mdd'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: {
          value: mddTrend,
          appearance: getTrendAppearance({ value: mddTrend, type: 'decreaseGood' }),
        },
        subtitle: {
          type: 'numeric',
          value: mdd.value,
          color: 'inverse--text',
        },
        tooltip: mddClosedInvoicesCount
          ? i18n.t('dashboard.help.mddExample', {
              month,
              count: mddClosedInvoicesCount,
              value: mdd.value,
            })
          : i18n.t('dashboard.help.mdd'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      closedInvoices: {
        id: 'closedInvoices',
        name: i18n.t('dashboard.closedInvoices'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: {
          type: 'currency',
          value: formatCurrency(closedInvoicesTotalAmount, currency, false),
          color: 'primary--text',
        },
        tooltip: i18n.t('dashboard.help.closedInvoices'),
        chart: undefined,
        toggleModal: false,
        withSearch: true,
        isTitleCardClickable: !isEqual(businessModel, FREE),
        withTopDateSelector: false,
        query: undefined,
        path: '/li/invoices',
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      workflowsActivities: {
        id: 'workflowsActivities',
        name: i18n.t('dashboard.workflowsActivities'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: i18n.t('dashboard.help.workflowsActivities'),
        chart: undefined,
        toggleModal: false,
        withSearch: true,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      notesActivities: {
        id: 'notesActivities',
        name: i18n.t('dashboard.notesActivities'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: i18n.t('dashboard.help.notesActivities'),
        chart: undefined,
        toggleModal: false,
        withSearch: true,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      topDebtors: {
        id: 'topDebtors',
        name: i18n.t('dashboard.topDebtors'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: undefined,
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      openInvoices: {
        id: 'openInvoices',
        name: i18n.t('dashboard.openInvoices'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: {
          value: countOutstandingInvoicesTrend,
          appearance: getTrendAppearance({ value: countOutstandingInvoicesTrend, type: 'neutral' }),
        },
        subtitle: {
          type: 'numeric',
          value: Number(outstandingInvoices).toLocaleString(),
          color: 'inverse--text',
        },
        tooltip: i18n.t('dashboard.help.openInvoices'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: !isEqual(businessModel, FREE),
        withTopDateSelector: false,
        query: undefined,
        path: '/li/invoices',
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      openInvoicesChart: {
        id: 'openInvoicesChart',
        name: i18n.t('dashboard.openInvoicesChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: i18n.t('dashboard.help.openInvoices'),
        chart: outstandingInvoicesChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: !isEqual(businessModel, FREE),
        withTopDateSelector: false,
        query: undefined,
        path: '/li/invoices',
        showExport: false,
        disabled: false,
        titleDate: undefined, // We will not show it on the grid but we can pick it as a widget
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      openInvoicesAmountChart: {
        id: 'openInvoicesAmountChart',
        name: i18n.t('dashboard.openInvoicesAmountChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: undefined,
        chart: outstandingInvoicesAmountChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: !isEqual(businessModel, FREE),
        withTopDateSelector: false,
        query: undefined,
        path: '/li/invoices',
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      customersRiskChart: {
        id: 'customersRiskChart',
        name: i18n.t('dashboard.customersRiskChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: undefined,
        chart: customersRiskChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: undefined,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      termsOfPayments: {
        id: 'termsOfPayments',
        name: i18n.t('dashboard.termsOfPayments'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: i18n.t('dashboard.help.termsOfPayments'),
        chart: termsOfPaymentsChart,
        toggleModal: false,
        withSearch: false,
        query: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      paymentGatewayChart: {
        id: 'paymentGatewayChart',
        name: i18n.t('dashboard.paymentGatewayChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: i18n.t('dashboard.help.paymentGatewayChart'),
        chart: paymentGatewayChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: !hasPaymentGateway && !isDemoCompany,
        isChartClickDisabled: false,
        isLocked: false,
      },
      paymentsHistoryChart: {
        id: 'paymentsHistoryChart',
        name: i18n.t('dashboard.paymentsHistoryChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: i18n.t('dashboard.help.paymentsHistoryChart'),
        chart: paymentsHistoryChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isLocked: false,
      },
      pastDueCustomers: {
        id: 'pastDueCustomers',
        name: i18n.t('dashboard.pastDueCustomers'),
        titleColor: undefined,
        titleAmount: {
          type: 'numeric',
          value: countOverdueCustomers,
          color: 'red--text text--darken-1',
        },
        trend: {
          value: countCustomersOverdueTrend,
          appearance: getTrendAppearance({ value: countCustomersOverdueTrend, type: 'decreaseGood' }),
        },
        subtitle: {
          type: 'percentage',
          value: percentageOverdueCustomers,
          color: 'grey--text',
        },
        tooltip: undefined,
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: !isEqual(businessModel, FREE),
        withTopDateSelector: false,
        query: getOverdueCustomersQuery(),
        path: '/li/customers',
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      payments: {
        id: 'payments',
        name: i18n.t('dashboard.payments'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: i18n.t('dashboard.help.payments'),
        chart: paymentsForecastChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: isDemoCompany,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: !isDemoCompany && !uiShowPaymentForecasting,
        isChartClickDisabled: false,
        isLocked: false,
      },
      customersChart: {
        id: 'customersChart',
        name: i18n.t('dashboard.customersChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: undefined,
        chart: customersChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: !isEqual(businessModel, FREE),
        withTopDateSelector: false,
        query: undefined,
        path: '/li/customers',
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        withUCRSelector: true,
        isChartClickDisabled: false,
        isLocked: false,
      },
      invoicesChart: {
        id: 'invoicesChart',
        name: i18n.t('dashboard.invoicesChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: undefined,
        chart: invoicesChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: !isEqual(businessModel, FREE),
        withTopDateSelector: false,
        query: undefined,
        path: '/li/invoices',
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      manualNotesActivities: {
        id: 'manualNotesActivities',
        name: i18n.t('dashboard.manualNotesActivities'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: undefined,
        chart: undefined,
        toggleModal: false,
        withSearch: true,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      inflation: {
        id: 'inflation',
        name: i18n.t('dashboard.inflation'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: {
          type: 'ratePercentage',
          value: getRateValue('inflation').value,
          color: 'teal--text text--lighten-2',
        },
        tooltip: undefined,
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: true,
        titleDate: getRateValue('inflation').date,
        visible: false,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      interest: {
        id: 'interest',
        name: i18n.t('dashboard.interest'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: {
          type: 'ratePercentage',
          value: getRateValue('interest').value,
          color: 'teal--text text--lighten-2',
        },
        tooltip: undefined,
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: true,
        titleDate: getRateValue('interest').date,
        visible: false,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      collectionRate: {
        id: 'collectionRate',
        name: i18n.t('dashboard.collectionRate'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: {
          value: collectionRateTrend,
          appearance: getTrendAppearance({ value: collectionRateTrend, type: 'increaseGood' }),
        },
        subtitle: {
          type: 'ratePercentage',
          value: collectionRateValue,
          color: 'inverse--text',
        },
        tooltip:
          collectionRateValue && !isString(collectionRateValue)
            ? i18n.t('dashboard.help.collectionRateExample', {
                month,
                collected: formatCurrency(collectedCollectionRate, currency, false),
                expectedCollected: formatCurrency(expectedCollectedCollectionRate, currency, false),
                value: collectionRateValue,
              })
            : i18n.t('dashboard.help.collectionRate'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      manualTimeSaved: {
        id: 'manualTimeSaved',
        name: i18n.t('dashboard.manualTimeSaved'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: {
          type: 'counter',
          value: manualTimeSavedValue,
          color: 'teal--text text--lighten-2',
        },
        tooltip: i18n.t('dashboard.help.manualTimeSaved'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      disputesChart: {
        id: 'disputesChart',
        name: i18n.t('dashboard.disputesChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: undefined,
        tooltip: undefined,
        chart: disputesChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: undefined,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: isDemoCompany,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      customersCreditLimit: {
        id: 'customersCreditLimit',
        name: i18n.t('dashboard.customersCreditLimit'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: {
          value: customersCreditLimitTrend,
          appearance: getTrendAppearance({ value: customersCreditLimitTrend, type: 'neutral' }),
        },
        subtitle: {
          type: 'currency',
          value: customersCreditLimit ? formatCurrency(customersCreditLimit, currency, false) : 'N/A',
          color: 'teal--text',
        },
        tooltip: i18n.t('dashboard.help.customersCreditLimit'),
        chart: undefined,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: false,
        withTopDateSelector: false,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: !isDemoCompany && !toggleCreditManagement,
        isChartClickDisabled: false,
        isLocked: false,
        premiumFeatureModule: 'creditManagement',
      },
      emailStatusMetricsChart: {
        id: 'emailStatusMetricsChart',
        name: i18n.t('dashboard.emailStatusMetricsChart'),
        titleColor: undefined,
        titleAmount: undefined,
        trend: undefined,
        subtitle: {
          type: 'engagement',
          value: [emailStatusMetricsChart?.emailsSent, emailStatusMetricsChart?.totalCustomers],
        },
        tooltip: i18n.t('dashboard.help.emailStatusMetricsChart'),
        chart: emailStatusMetricsChart,
        toggleModal: false,
        withSearch: false,
        isTitleCardClickable: undefined,
        withTopDateSelector: true,
        query: undefined,
        path: undefined,
        showExport: false,
        disabled: false,
        titleDate: undefined,
        visible: true,
        isFeatureDisabled: false,
        isChartClickDisabled: false,
        isLocked: false,
      },
      ...customWidgets,
    };
  },
};
