const WORKFLOW = {
  TYPES: {
    PRINT: 'print',
    SMS: 'sms',
  },
};

Object.freeze(WORKFLOW);

export default WORKFLOW;
