const DEVICE = {
  ORIENTATION: {
    PORTRAIT: 'portrait_primary',
    LANDSCAPE: 'landscape_primary',
  },
};

Object.freeze(DEVICE);

export default DEVICE;
