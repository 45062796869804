/* eslint-disable */
import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = import.meta.env.VITE_APP_NON_API_URL;
Vue.axios.defaults.headers.common['Cache-Control'] = 'no-cache';
Vue.axios.defaults.withCredentials = true;

const companyId = localStorage.getItem('GAV-company-id');
const accessToken = localStorage.getItem('GAV_accessToken');

if (companyId) {
  Vue.axios.defaults.headers.common['GAV-company-id'] = companyId;
}

if (accessToken) {
  Vue.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
}

export default {
  root: import.meta.env.VITE_APP_NON_API_URL,
};
