import { isEqual } from 'lodash-es';

export default {
  SET_APP_INSIGHTS: (state, payload) => {
    state.appInsights = payload;
  },
  SET_APP_DEMO_INSIGHTS: (state, payload) => {
    state.demoAppInsights = payload;
  },
  SET_APP_INSIGHTS_COUNT: (state, payload) => {
    state.appInsightsCount = payload;
  },
  SET_DEMO_APP_INSIGHTS_COUNT: (state, payload) => {
    state.demoAppInsightsCount = payload;
  },
  SET_UPDATE_DEMO_APP_INSIGHT_STATUS: (state, payload) => {
    const insight = state.demoAppInsights.find(({ id }) => isEqual(id, payload.id));

    if (insight) {
      insight.status = 'read';
      state.demoAppInsightsCount -= 1;
    }
  },
  SET_IS_APP_INSIGHTS_LOADING: (state, payload) => {
    state.isAppInsightsLoading = payload;
  },
};
