const modes = {
  VIEW: 'view',
  PREVIEW: 'preview',
  UPLOAD: 'upload',
  CREATE: 'create',
  EDIT: 'edit',
  ACTIVITY: 'activity',
  DETAIL_VIEW: 'detail-view',
  NOTE_VIEW: 'note-view',
  ATTACHMENT_VIEW: 'attachment-view',
};

const MODES = Object.freeze(modes);

export default MODES;
