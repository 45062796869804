import { i18n } from '@plugins';

export default {
  activityLog: ({ activityLogData }) => activityLogData,
  customers: ({ activityLogDataCustomers }) => activityLogDataCustomers,
  invoices: ({ activityLogDataInvoices }) => activityLogDataInvoices,
  pagesCount: ({ pagesCount }) => pagesCount,
  activityCount: ({ activityCount }) => activityCount,
  activityTypes: () => [
    {
      id: 'note',
      name: i18n.t('advancedFilters.types.note'),
    },
    {
      id: 'workflowEvent',
      name: i18n.t('advancedFilters.types.workflowEvent'),
    },
    {
      id: 'email',
      name: i18n.t('advancedFilters.types.workflowEmail'),
    },
    {
      id: 'customerResponse',
      name: i18n.t('advancedFilters.types.customerResponse'),
    },
    {
      id: 'replyToCustomer',
      name: i18n.t('advancedFilters.types.replyToCustomer'),
    },
    {
      id: 'sms',
      name: i18n.t('advancedFilters.types.workflowSms'),
    },
    {
      id: 'print',
      name: i18n.t('advancedFilters.types.print'),
    },
    {
      id: 'workflowOnePerCustomer',
      name: i18n.t('advancedFilters.types.workflowOnePerCustomer'),
    },
    {
      id: 'missingEmail',
      name: i18n.t('advancedFilters.types.missingEmail'),
    },
    {
      id: 'cashApplication',
      name: i18n.t('advancedFilters.types.cashApplication'),
    },
    {
      id: 'missingPhone',
      name: i18n.t('advancedFilters.types.missingPhone'),
    },
    {
      id: 'payment',
      name: i18n.t('advancedFilters.types.payment'),
    },
    {
      id: 'call',
      name: i18n.t('advancedFilters.types.call'),
    },
    {
      id: 'creditManagement',
      name: i18n.t('advancedFilters.types.creditManagement'),
    },
    {
      id: 'dispute',
      name: i18n.t('advancedFilters.types.disputeManagement'),
    },
  ],
  emailEvents: () => [
    {
      name: i18n.t('advancedFilters.open'),
      id: 'open',
    },
    {
      name: i18n.t('advancedFilters.bounce'),
      id: 'bounce',
    },
    {
      name: i18n.t('advancedFilters.dropped'),
      id: 'dropped',
    },
    {
      name: i18n.t('advancedFilters.deferred'),
      id: 'deferred',
    },
    {
      name: i18n.t('advancedFilters.delivered'),
      id: 'delivered',
    },
    {
      name: i18n.t('dashboard.charts.emailStatusMetricsChart.spamreport'),
      id: 'spamreport',
    },
  ],
  reminderStatuses: () => [
    {
      value: 'open',
      label: i18n.t('advancedFilters.open'),
    },
    {
      value: 'done',
      label: i18n.t('advancedFilters.done'),
    },
  ],
  emailRecipients: () => [
    {
      value: 'customer',
      label: i18n.t('advancedFilters.customer'),
    },
    {
      value: 'internal',
      label: i18n.t('advancedFilters.internal'),
    },
  ],
  ruleTriggers: () => [
    {
      id: 'created',
      name: i18n.t('advancedFilters.ruleTriggers.created'),
    },
    {
      id: 'beforeDueDate',
      name: i18n.t('advancedFilters.ruleTriggers.beforeDueDate'),
    },
    {
      id: 'onDueDate',
      name: i18n.t('advancedFilters.ruleTriggers.onDueDate'),
    },
    {
      id: 'afterDueDate',
      name: i18n.t('advancedFilters.ruleTriggers.afterDueDate'),
    },
    {
      id: 'closed',
      name: i18n.t('advancedFilters.ruleTriggers.closed'),
    },
    {
      id: 'dataChange',
      name: i18n.t('advancedFilters.ruleTriggers.dataChange'),
    },
    {
      id: 'recurring',
      name: i18n.t('advancedFilters.ruleTriggers.recurring'),
    },
  ],
};
